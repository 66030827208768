@charset "UTF-8";
/* transition */
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #286efb;
  --secondary: #6a747b;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f6f9fc;
  --dark: #2d343e;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #6a747b;
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1.3rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #286efb;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0451ec;
  text-decoration: none;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1rem;
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  line-height: 1.35;
  color: #2d343e;
}

h1, .h1 {
  font-size: 2.5rem;
}

@media (max-width: 1200px) {
  h1, .h1 {
    font-size: calc(1.375rem + 1.5vw) ;
  }
}

h2, .h2 {
  font-size: 2rem;
}

@media (max-width: 1200px) {
  h2, .h2 {
    font-size: calc(1.325rem + 0.9vw) ;
  }
}

h3, .h3 {
  font-size: 1.75rem;
}

@media (max-width: 1200px) {
  h3, .h3 {
    font-size: calc(1.3rem + 0.6vw) ;
  }
}

h4, .h4 {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  h4, .h4 {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.725rem + 5.7vw) ;
  }
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-2 {
    font-size: calc(1.675rem + 5.1vw) ;
  }
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-3 {
    font-size: calc(1.575rem + 3.9vw) ;
  }
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  .display-4 {
    font-size: calc(1.475rem + 2.7vw) ;
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 500;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.container-fluid, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1200px;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -ms-flex: 0 0 20%;
      flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
      flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
      order: -1;
}

.order-last {
  -ms-flex-order: 13;
      order: 13;
}

.order-0 {
  -ms-flex-order: 0;
      order: 0;
}

.order-1 {
  -ms-flex-order: 1;
      order: 1;
}

.order-2 {
  -ms-flex-order: 2;
      order: 2;
}

.order-3 {
  -ms-flex-order: 3;
      order: 3;
}

.order-4 {
  -ms-flex-order: 4;
      order: 4;
}

.order-5 {
  -ms-flex-order: 5;
      order: 5;
}

.order-6 {
  -ms-flex-order: 6;
      order: 6;
}

.order-7 {
  -ms-flex-order: 7;
      order: 7;
}

.order-8 {
  -ms-flex-order: 8;
      order: 8;
}

.order-9 {
  -ms-flex-order: 9;
      order: 9;
}

.order-10 {
  -ms-flex-order: 10;
      order: 10;
}

.order-11 {
  -ms-flex-order: 11;
      order: 11;
}

.order-12 {
  -ms-flex-order: 12;
      order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
        order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
        order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
        order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
        order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
        order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
        order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
        order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
        order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
        order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
        order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
        order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
        order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c3d6fe;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8fb4fd;
}

.table-hover .table-primary:hover {
  background-color: #aac5fe;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #aac5fe;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d5d8da;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b2b7ba;
}

.table-hover .table-secondary:hover {
  background-color: #c7cbce;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c7cbce;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fafcfd;
}

.table-hover .table-light:hover {
  background-color: #e9f0f8;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e9f0f8;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c4c6c9;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #92959b;
}

.table-hover .table-dark:hover {
  background-color: #b7b9bd;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b7b9bd;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #dfe2e5;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #286efb;
  outline: 0;
  box-shadow: none;
}

.form-control::-webkit-input-placeholder {
  color: #d0d4d9;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #d0d4d9;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #d0d4d9;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #d0d4d9;
  opacity: 1;
}

.form-control::placeholder {
  color: #d0d4d9;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f0f1f3;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.8rem + 1px);
  padding-bottom: calc(0.8rem + 1px);
  font-size: 1.25rem;
  line-height: 1.8;
}

.col-form-label-sm {
  padding-top: calc(0.4rem + 1px);
  padding-bottom: calc(0.4rem + 1px);
  font-size: 0.9rem;
  line-height: 1.4;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.6;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: auto;
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
  line-height: 1.4;
  border-radius: 3px;
}

.form-control-lg {
  height: auto;
  padding: 0.8rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.8;
  border-radius: 3px;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 1px rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 1px rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 1px rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -ms-flex-align: center;
      align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -ms-flex-align: center;
        align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 500;
  color: #6a747b;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.6;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #6a747b;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(40, 110, 251, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #286efb;
  border-color: #286efb;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0555f8;
  border-color: #0451ec;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0555f8;
  border-color: #0451ec;
  box-shadow: 0 0 0 0 rgba(72, 132, 252, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #286efb;
  border-color: #286efb;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0451ec;
  border-color: #044ddf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(72, 132, 252, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6a747b;
  border-color: #6a747b;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #586166;
  border-color: #525a60;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #586166;
  border-color: #525a60;
  box-shadow: 0 0 0 0 rgba(128, 137, 143, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6a747b;
  border-color: #6a747b;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #525a60;
  border-color: #4c5459;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(128, 137, 143, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0 rgba(72, 180, 97, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0 rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f6f9fc;
  border-color: #f6f9fc;
}

.btn-light:hover {
  color: #212529;
  background-color: #d9e6f2;
  border-color: #d0e0ef;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #d9e6f2;
  border-color: #d0e0ef;
  box-shadow: 0 0 0 0 rgba(214, 217, 220, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f6f9fc;
  border-color: #f6f9fc;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #d0e0ef;
  border-color: #c6d9ec;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(214, 217, 220, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #2d343e;
  border-color: #2d343e;
}

.btn-dark:hover {
  color: #fff;
  background-color: #1d2128;
  border-color: #181b20;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #1d2128;
  border-color: #181b20;
  box-shadow: 0 0 0 0 rgba(77, 82, 91, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #2d343e;
  border-color: #2d343e;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #181b20;
  border-color: #121519;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(77, 82, 91, 0.5);
}

.btn-outline-primary {
  color: #286efb;
  border-color: #286efb;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #286efb;
  border-color: #286efb;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0 rgba(40, 110, 251, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #286efb;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #286efb;
  border-color: #286efb;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(40, 110, 251, 0.5);
}

.btn-outline-secondary {
  color: #6a747b;
  border-color: #6a747b;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6a747b;
  border-color: #6a747b;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0 rgba(106, 116, 123, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6a747b;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6a747b;
  border-color: #6a747b;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(106, 116, 123, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f6f9fc;
  border-color: #f6f9fc;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f6f9fc;
  border-color: #f6f9fc;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0 rgba(246, 249, 252, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f6f9fc;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f6f9fc;
  border-color: #f6f9fc;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(246, 249, 252, 0.5);
}

.btn-outline-dark {
  color: #2d343e;
  border-color: #2d343e;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #2d343e;
  border-color: #2d343e;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0 rgba(45, 52, 62, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #2d343e;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #2d343e;
  border-color: #2d343e;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(45, 52, 62, 0.5);
}

.btn-link {
  font-weight: 500;
  color: #286efb;
  text-decoration: none;
}

.btn-link:hover {
  color: #0451ec;
  text-decoration: none;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: none;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6a747b;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.8rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.8;
  border-radius: 3px;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
  line-height: 1.4;
  border-radius: 3px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 12rem;
  padding: 1rem 0;
  margin: 0 0 0;
  font-size: 1rem;
  color: #6a747b;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f0f1f3;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.4rem 1.5rem;
  clear: both;
  font-weight: 500;
  color: #6a747b !important;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #286efb !important;
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item.active, .dropdown-item:active {
  color: #286efb;
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6a747b;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #2d343e;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.4rem 1.5rem;
  color: #6a747b !important;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.6rem;
  padding-left: 0.6rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: center;
      justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: stretch;
      align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -ms-flex: 1 1 0%;
      flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 3px;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: auto;
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.8rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.8;
  border-radius: 3px;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: auto;
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
  line-height: 1.4;
  border-radius: 3px;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.6rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.3rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.3rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.3rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.3rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
          transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: auto;
  padding: 0.5rem 2rem 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #dfe2e5;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.custom-select:focus {
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: auto;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: auto;
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #286efb;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: auto;
  padding: 0.5rem 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  border: 1px solid #dfe2e5;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: auto;
  padding: 0.5rem 1rem;
  line-height: 1.6;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
       appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.25rem 0.75rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6a747b;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6a747b;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 0 0;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-xl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 0;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-align: center;
      align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-xl {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-xl {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-xl {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-xl {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
      flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-xl {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: #6a747b;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #286efb;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #286efb;
}

.navbar-light .navbar-toggler {
  color: #6a747b;
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #6a747b;
}

.navbar-light .navbar-text a {
  color: #286efb;
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #286efb;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #286efb;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #286efb;
}

.navbar-dark .navbar-toggler {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #ffffff;
}

.navbar-dark .navbar-text a {
  color: #286efb;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #286efb;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 3px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.8;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.4;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #286efb;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0451ec;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 110, 251, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6a747b;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #525a60;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(106, 116, 123, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f6f9fc;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #d0e0ef;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(246, 249, 252, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #2d343e;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #181b20;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(45, 52, 62, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #153983;
  background-color: #d4e2fe;
  border-color: #c3d6fe;
}

.alert-primary hr {
  border-top-color: #aac5fe;
}

.alert-primary .alert-link {
  color: #0e2657;
}

.alert-secondary {
  color: #373c40;
  background-color: #e1e3e5;
  border-color: #d5d8da;
}

.alert-secondary hr {
  border-top-color: #c7cbce;
}

.alert-secondary .alert-link {
  color: #1f2225;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #808183;
  background-color: #fdfefe;
  border-color: #fcfdfe;
}

.alert-light hr {
  border-top-color: #e9f0f8;
}

.alert-light .alert-link {
  color: #676869;
}

.alert-dark {
  color: #171b20;
  background-color: #d5d6d8;
  border-color: #c4c6c9;
}

.alert-dark hr {
  border-top-color: #b7b9bd;
}

.alert-dark .alert-link {
  color: #020202;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
            animation: none;
  }
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
      flex: 1;
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  -ms-flex-direction: row;
      flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #153983;
  background-color: #c3d6fe;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #153983;
  background-color: #aac5fe;
}

.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #153983;
  border-color: #153983;
}

.list-group-item-secondary {
  color: #373c40;
  background-color: #d5d8da;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #373c40;
  background-color: #c7cbce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #373c40;
  border-color: #373c40;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #808183;
  background-color: #fcfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #808183;
  background-color: #e9f0f8;
}

.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #808183;
  border-color: #808183;
}

.list-group-item-dark {
  color: #171b20;
  background-color: #c4c6c9;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #171b20;
  background-color: #b7b9bd;
}

.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #171b20;
  border-color: #171b20;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

@media (max-width: 1200px) {
  .close {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
          transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
          transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #2d343e;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
      touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #286efb !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0451ec !important;
}

.bg-secondary {
  background-color: #6a747b !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #525a60 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f6f9fc !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d0e0ef !important;
}

.bg-dark {
  background-color: #2d343e !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #181b20 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #286efb !important;
}

.border-secondary {
  border-color: #6a747b !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f6f9fc !important;
}

.border-dark {
  border-color: #2d343e !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 3px !important;
}

.rounded-top {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.rounded-right {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.rounded-left {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -ms-flex-direction: row !important;
      flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
      flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
      justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
      justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
      justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
      justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
      align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
      align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
      align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
      align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
      align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 2.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 2.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 2.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 3.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 3.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 3.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.mt-9,
.my-9 {
  margin-top: 4rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 4rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 4rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 4rem !important;
}

.m-10 {
  margin: 4.5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 4.5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 4.5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 4.5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 4.5rem !important;
}

.m-11 {
  margin: 5rem !important;
}

.mt-11,
.my-11 {
  margin-top: 5rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 5rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 5rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 5rem !important;
}

.m-12 {
  margin: 5.5rem !important;
}

.mt-12,
.my-12 {
  margin-top: 5.5rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 5.5rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 5.5rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 5.5rem !important;
}

.m-13 {
  margin: 6rem !important;
}

.mt-13,
.my-13 {
  margin-top: 6rem !important;
}

.mr-13,
.mx-13 {
  margin-right: 6rem !important;
}

.mb-13,
.my-13 {
  margin-bottom: 6rem !important;
}

.ml-13,
.mx-13 {
  margin-left: 6rem !important;
}

.m-14 {
  margin: 6.5rem !important;
}

.mt-14,
.my-14 {
  margin-top: 6.5rem !important;
}

.mr-14,
.mx-14 {
  margin-right: 6.5rem !important;
}

.mb-14,
.my-14 {
  margin-bottom: 6.5rem !important;
}

.ml-14,
.mx-14 {
  margin-left: 6.5rem !important;
}

.m-15 {
  margin: 7rem !important;
}

.mt-15,
.my-15 {
  margin-top: 7rem !important;
}

.mr-15,
.mx-15 {
  margin-right: 7rem !important;
}

.mb-15,
.my-15 {
  margin-bottom: 7rem !important;
}

.ml-15,
.mx-15 {
  margin-left: 7rem !important;
}

.m-16 {
  margin: 7.5rem !important;
}

.mt-16,
.my-16 {
  margin-top: 7.5rem !important;
}

.mr-16,
.mx-16 {
  margin-right: 7.5rem !important;
}

.mb-16,
.my-16 {
  margin-bottom: 7.5rem !important;
}

.ml-16,
.mx-16 {
  margin-left: 7.5rem !important;
}

.m-17 {
  margin: 8rem !important;
}

.mt-17,
.my-17 {
  margin-top: 8rem !important;
}

.mr-17,
.mx-17 {
  margin-right: 8rem !important;
}

.mb-17,
.my-17 {
  margin-bottom: 8rem !important;
}

.ml-17,
.mx-17 {
  margin-left: 8rem !important;
}

.m-18 {
  margin: 8.5rem !important;
}

.mt-18,
.my-18 {
  margin-top: 8.5rem !important;
}

.mr-18,
.mx-18 {
  margin-right: 8.5rem !important;
}

.mb-18,
.my-18 {
  margin-bottom: 8.5rem !important;
}

.ml-18,
.mx-18 {
  margin-left: 8.5rem !important;
}

.m-19 {
  margin: 9rem !important;
}

.mt-19,
.my-19 {
  margin-top: 9rem !important;
}

.mr-19,
.mx-19 {
  margin-right: 9rem !important;
}

.mb-19,
.my-19 {
  margin-bottom: 9rem !important;
}

.ml-19,
.mx-19 {
  margin-left: 9rem !important;
}

.m-20 {
  margin: 9.5rem !important;
}

.mt-20,
.my-20 {
  margin-top: 9.5rem !important;
}

.mr-20,
.mx-20 {
  margin-right: 9.5rem !important;
}

.mb-20,
.my-20 {
  margin-bottom: 9.5rem !important;
}

.ml-20,
.mx-20 {
  margin-left: 9.5rem !important;
}

.m-21 {
  margin: 10rem !important;
}

.mt-21,
.my-21 {
  margin-top: 10rem !important;
}

.mr-21,
.mx-21 {
  margin-right: 10rem !important;
}

.mb-21,
.my-21 {
  margin-bottom: 10rem !important;
}

.ml-21,
.mx-21 {
  margin-left: 10rem !important;
}

.m-22 {
  margin: 11.5rem !important;
}

.mt-22,
.my-22 {
  margin-top: 11.5rem !important;
}

.mr-22,
.mx-22 {
  margin-right: 11.5rem !important;
}

.mb-22,
.my-22 {
  margin-bottom: 11.5rem !important;
}

.ml-22,
.mx-22 {
  margin-left: 11.5rem !important;
}

.m-23 {
  margin: 12rem !important;
}

.mt-23,
.my-23 {
  margin-top: 12rem !important;
}

.mr-23,
.mx-23 {
  margin-right: 12rem !important;
}

.mb-23,
.my-23 {
  margin-bottom: 12rem !important;
}

.ml-23,
.mx-23 {
  margin-left: 12rem !important;
}

.m-24 {
  margin: 12.5rem !important;
}

.mt-24,
.my-24 {
  margin-top: 12.5rem !important;
}

.mr-24,
.mx-24 {
  margin-right: 12.5rem !important;
}

.mb-24,
.my-24 {
  margin-bottom: 12.5rem !important;
}

.ml-24,
.mx-24 {
  margin-left: 12.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 2.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 2.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 2.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.pt-7,
.py-7 {
  padding-top: 3rem !important;
}

.pr-7,
.px-7 {
  padding-right: 3rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3rem !important;
}

.pl-7,
.px-7 {
  padding-left: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 3.5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 3.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 3.5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.pt-9,
.py-9 {
  padding-top: 4rem !important;
}

.pr-9,
.px-9 {
  padding-right: 4rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 4rem !important;
}

.pl-9,
.px-9 {
  padding-left: 4rem !important;
}

.p-10 {
  padding: 4.5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 4.5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 4.5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 4.5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 4.5rem !important;
}

.p-11 {
  padding: 5rem !important;
}

.pt-11,
.py-11 {
  padding-top: 5rem !important;
}

.pr-11,
.px-11 {
  padding-right: 5rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 5rem !important;
}

.pl-11,
.px-11 {
  padding-left: 5rem !important;
}

.p-12 {
  padding: 5.5rem !important;
}

.pt-12,
.py-12 {
  padding-top: 5.5rem !important;
}

.pr-12,
.px-12 {
  padding-right: 5.5rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 5.5rem !important;
}

.pl-12,
.px-12 {
  padding-left: 5.5rem !important;
}

.p-13 {
  padding: 6rem !important;
}

.pt-13,
.py-13 {
  padding-top: 6rem !important;
}

.pr-13,
.px-13 {
  padding-right: 6rem !important;
}

.pb-13,
.py-13 {
  padding-bottom: 6rem !important;
}

.pl-13,
.px-13 {
  padding-left: 6rem !important;
}

.p-14 {
  padding: 6.5rem !important;
}

.pt-14,
.py-14 {
  padding-top: 6.5rem !important;
}

.pr-14,
.px-14 {
  padding-right: 6.5rem !important;
}

.pb-14,
.py-14 {
  padding-bottom: 6.5rem !important;
}

.pl-14,
.px-14 {
  padding-left: 6.5rem !important;
}

.p-15 {
  padding: 7rem !important;
}

.pt-15,
.py-15 {
  padding-top: 7rem !important;
}

.pr-15,
.px-15 {
  padding-right: 7rem !important;
}

.pb-15,
.py-15 {
  padding-bottom: 7rem !important;
}

.pl-15,
.px-15 {
  padding-left: 7rem !important;
}

.p-16 {
  padding: 7.5rem !important;
}

.pt-16,
.py-16 {
  padding-top: 7.5rem !important;
}

.pr-16,
.px-16 {
  padding-right: 7.5rem !important;
}

.pb-16,
.py-16 {
  padding-bottom: 7.5rem !important;
}

.pl-16,
.px-16 {
  padding-left: 7.5rem !important;
}

.p-17 {
  padding: 8rem !important;
}

.pt-17,
.py-17 {
  padding-top: 8rem !important;
}

.pr-17,
.px-17 {
  padding-right: 8rem !important;
}

.pb-17,
.py-17 {
  padding-bottom: 8rem !important;
}

.pl-17,
.px-17 {
  padding-left: 8rem !important;
}

.p-18 {
  padding: 8.5rem !important;
}

.pt-18,
.py-18 {
  padding-top: 8.5rem !important;
}

.pr-18,
.px-18 {
  padding-right: 8.5rem !important;
}

.pb-18,
.py-18 {
  padding-bottom: 8.5rem !important;
}

.pl-18,
.px-18 {
  padding-left: 8.5rem !important;
}

.p-19 {
  padding: 9rem !important;
}

.pt-19,
.py-19 {
  padding-top: 9rem !important;
}

.pr-19,
.px-19 {
  padding-right: 9rem !important;
}

.pb-19,
.py-19 {
  padding-bottom: 9rem !important;
}

.pl-19,
.px-19 {
  padding-left: 9rem !important;
}

.p-20 {
  padding: 9.5rem !important;
}

.pt-20,
.py-20 {
  padding-top: 9.5rem !important;
}

.pr-20,
.px-20 {
  padding-right: 9.5rem !important;
}

.pb-20,
.py-20 {
  padding-bottom: 9.5rem !important;
}

.pl-20,
.px-20 {
  padding-left: 9.5rem !important;
}

.p-21 {
  padding: 10rem !important;
}

.pt-21,
.py-21 {
  padding-top: 10rem !important;
}

.pr-21,
.px-21 {
  padding-right: 10rem !important;
}

.pb-21,
.py-21 {
  padding-bottom: 10rem !important;
}

.pl-21,
.px-21 {
  padding-left: 10rem !important;
}

.p-22 {
  padding: 11.5rem !important;
}

.pt-22,
.py-22 {
  padding-top: 11.5rem !important;
}

.pr-22,
.px-22 {
  padding-right: 11.5rem !important;
}

.pb-22,
.py-22 {
  padding-bottom: 11.5rem !important;
}

.pl-22,
.px-22 {
  padding-left: 11.5rem !important;
}

.p-23 {
  padding: 12rem !important;
}

.pt-23,
.py-23 {
  padding-top: 12rem !important;
}

.pr-23,
.px-23 {
  padding-right: 12rem !important;
}

.pb-23,
.py-23 {
  padding-bottom: 12rem !important;
}

.pl-23,
.px-23 {
  padding-left: 12rem !important;
}

.p-24 {
  padding: 12.5rem !important;
}

.pt-24,
.py-24 {
  padding-top: 12.5rem !important;
}

.pr-24,
.px-24 {
  padding-right: 12.5rem !important;
}

.pb-24,
.py-24 {
  padding-bottom: 12.5rem !important;
}

.pl-24,
.px-24 {
  padding-left: 12.5rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -2rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -2rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -2rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -2.5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -2.5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -2.5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -3rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -3rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -3rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -3.5rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -3.5rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -3.5rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -4rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -4rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -4rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -4rem !important;
}

.m-n10 {
  margin: -4.5rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -4.5rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -4.5rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -4.5rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -4.5rem !important;
}

.m-n11 {
  margin: -5rem !important;
}

.mt-n11,
.my-n11 {
  margin-top: -5rem !important;
}

.mr-n11,
.mx-n11 {
  margin-right: -5rem !important;
}

.mb-n11,
.my-n11 {
  margin-bottom: -5rem !important;
}

.ml-n11,
.mx-n11 {
  margin-left: -5rem !important;
}

.m-n12 {
  margin: -5.5rem !important;
}

.mt-n12,
.my-n12 {
  margin-top: -5.5rem !important;
}

.mr-n12,
.mx-n12 {
  margin-right: -5.5rem !important;
}

.mb-n12,
.my-n12 {
  margin-bottom: -5.5rem !important;
}

.ml-n12,
.mx-n12 {
  margin-left: -5.5rem !important;
}

.m-n13 {
  margin: -6rem !important;
}

.mt-n13,
.my-n13 {
  margin-top: -6rem !important;
}

.mr-n13,
.mx-n13 {
  margin-right: -6rem !important;
}

.mb-n13,
.my-n13 {
  margin-bottom: -6rem !important;
}

.ml-n13,
.mx-n13 {
  margin-left: -6rem !important;
}

.m-n14 {
  margin: -6.5rem !important;
}

.mt-n14,
.my-n14 {
  margin-top: -6.5rem !important;
}

.mr-n14,
.mx-n14 {
  margin-right: -6.5rem !important;
}

.mb-n14,
.my-n14 {
  margin-bottom: -6.5rem !important;
}

.ml-n14,
.mx-n14 {
  margin-left: -6.5rem !important;
}

.m-n15 {
  margin: -7rem !important;
}

.mt-n15,
.my-n15 {
  margin-top: -7rem !important;
}

.mr-n15,
.mx-n15 {
  margin-right: -7rem !important;
}

.mb-n15,
.my-n15 {
  margin-bottom: -7rem !important;
}

.ml-n15,
.mx-n15 {
  margin-left: -7rem !important;
}

.m-n16 {
  margin: -7.5rem !important;
}

.mt-n16,
.my-n16 {
  margin-top: -7.5rem !important;
}

.mr-n16,
.mx-n16 {
  margin-right: -7.5rem !important;
}

.mb-n16,
.my-n16 {
  margin-bottom: -7.5rem !important;
}

.ml-n16,
.mx-n16 {
  margin-left: -7.5rem !important;
}

.m-n17 {
  margin: -8rem !important;
}

.mt-n17,
.my-n17 {
  margin-top: -8rem !important;
}

.mr-n17,
.mx-n17 {
  margin-right: -8rem !important;
}

.mb-n17,
.my-n17 {
  margin-bottom: -8rem !important;
}

.ml-n17,
.mx-n17 {
  margin-left: -8rem !important;
}

.m-n18 {
  margin: -8.5rem !important;
}

.mt-n18,
.my-n18 {
  margin-top: -8.5rem !important;
}

.mr-n18,
.mx-n18 {
  margin-right: -8.5rem !important;
}

.mb-n18,
.my-n18 {
  margin-bottom: -8.5rem !important;
}

.ml-n18,
.mx-n18 {
  margin-left: -8.5rem !important;
}

.m-n19 {
  margin: -9rem !important;
}

.mt-n19,
.my-n19 {
  margin-top: -9rem !important;
}

.mr-n19,
.mx-n19 {
  margin-right: -9rem !important;
}

.mb-n19,
.my-n19 {
  margin-bottom: -9rem !important;
}

.ml-n19,
.mx-n19 {
  margin-left: -9rem !important;
}

.m-n20 {
  margin: -9.5rem !important;
}

.mt-n20,
.my-n20 {
  margin-top: -9.5rem !important;
}

.mr-n20,
.mx-n20 {
  margin-right: -9.5rem !important;
}

.mb-n20,
.my-n20 {
  margin-bottom: -9.5rem !important;
}

.ml-n20,
.mx-n20 {
  margin-left: -9.5rem !important;
}

.m-n21 {
  margin: -10rem !important;
}

.mt-n21,
.my-n21 {
  margin-top: -10rem !important;
}

.mr-n21,
.mx-n21 {
  margin-right: -10rem !important;
}

.mb-n21,
.my-n21 {
  margin-bottom: -10rem !important;
}

.ml-n21,
.mx-n21 {
  margin-left: -10rem !important;
}

.m-n22 {
  margin: -11.5rem !important;
}

.mt-n22,
.my-n22 {
  margin-top: -11.5rem !important;
}

.mr-n22,
.mx-n22 {
  margin-right: -11.5rem !important;
}

.mb-n22,
.my-n22 {
  margin-bottom: -11.5rem !important;
}

.ml-n22,
.mx-n22 {
  margin-left: -11.5rem !important;
}

.m-n23 {
  margin: -12rem !important;
}

.mt-n23,
.my-n23 {
  margin-top: -12rem !important;
}

.mr-n23,
.mx-n23 {
  margin-right: -12rem !important;
}

.mb-n23,
.my-n23 {
  margin-bottom: -12rem !important;
}

.ml-n23,
.mx-n23 {
  margin-left: -12rem !important;
}

.m-n24 {
  margin: -12.5rem !important;
}

.mt-n24,
.my-n24 {
  margin-top: -12.5rem !important;
}

.mr-n24,
.mx-n24 {
  margin-right: -12.5rem !important;
}

.mb-n24,
.my-n24 {
  margin-bottom: -12.5rem !important;
}

.ml-n24,
.mx-n24 {
  margin-left: -12.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2rem !important;
  }
  .m-sm-6 {
    margin: 2.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 2.5rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3rem !important;
  }
  .m-sm-8 {
    margin: 3.5rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 3.5rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 3.5rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 3.5rem !important;
  }
  .m-sm-9 {
    margin: 4rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 4rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 4rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 4rem !important;
  }
  .m-sm-10 {
    margin: 4.5rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 4.5rem !important;
  }
  .m-sm-11 {
    margin: 5rem !important;
  }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 5rem !important;
  }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 5rem !important;
  }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 5rem !important;
  }
  .m-sm-12 {
    margin: 5.5rem !important;
  }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 5.5rem !important;
  }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 5.5rem !important;
  }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 5.5rem !important;
  }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 5.5rem !important;
  }
  .m-sm-13 {
    margin: 6rem !important;
  }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 6rem !important;
  }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 6rem !important;
  }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 6rem !important;
  }
  .m-sm-14 {
    margin: 6.5rem !important;
  }
  .mt-sm-14,
  .my-sm-14 {
    margin-top: 6.5rem !important;
  }
  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 6.5rem !important;
  }
  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 6.5rem !important;
  }
  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 6.5rem !important;
  }
  .m-sm-15 {
    margin: 7rem !important;
  }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 7rem !important;
  }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 7rem !important;
  }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 7rem !important;
  }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 7rem !important;
  }
  .m-sm-16 {
    margin: 7.5rem !important;
  }
  .mt-sm-16,
  .my-sm-16 {
    margin-top: 7.5rem !important;
  }
  .mr-sm-16,
  .mx-sm-16 {
    margin-right: 7.5rem !important;
  }
  .mb-sm-16,
  .my-sm-16 {
    margin-bottom: 7.5rem !important;
  }
  .ml-sm-16,
  .mx-sm-16 {
    margin-left: 7.5rem !important;
  }
  .m-sm-17 {
    margin: 8rem !important;
  }
  .mt-sm-17,
  .my-sm-17 {
    margin-top: 8rem !important;
  }
  .mr-sm-17,
  .mx-sm-17 {
    margin-right: 8rem !important;
  }
  .mb-sm-17,
  .my-sm-17 {
    margin-bottom: 8rem !important;
  }
  .ml-sm-17,
  .mx-sm-17 {
    margin-left: 8rem !important;
  }
  .m-sm-18 {
    margin: 8.5rem !important;
  }
  .mt-sm-18,
  .my-sm-18 {
    margin-top: 8.5rem !important;
  }
  .mr-sm-18,
  .mx-sm-18 {
    margin-right: 8.5rem !important;
  }
  .mb-sm-18,
  .my-sm-18 {
    margin-bottom: 8.5rem !important;
  }
  .ml-sm-18,
  .mx-sm-18 {
    margin-left: 8.5rem !important;
  }
  .m-sm-19 {
    margin: 9rem !important;
  }
  .mt-sm-19,
  .my-sm-19 {
    margin-top: 9rem !important;
  }
  .mr-sm-19,
  .mx-sm-19 {
    margin-right: 9rem !important;
  }
  .mb-sm-19,
  .my-sm-19 {
    margin-bottom: 9rem !important;
  }
  .ml-sm-19,
  .mx-sm-19 {
    margin-left: 9rem !important;
  }
  .m-sm-20 {
    margin: 9.5rem !important;
  }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 9.5rem !important;
  }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 9.5rem !important;
  }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 9.5rem !important;
  }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 9.5rem !important;
  }
  .m-sm-21 {
    margin: 10rem !important;
  }
  .mt-sm-21,
  .my-sm-21 {
    margin-top: 10rem !important;
  }
  .mr-sm-21,
  .mx-sm-21 {
    margin-right: 10rem !important;
  }
  .mb-sm-21,
  .my-sm-21 {
    margin-bottom: 10rem !important;
  }
  .ml-sm-21,
  .mx-sm-21 {
    margin-left: 10rem !important;
  }
  .m-sm-22 {
    margin: 11.5rem !important;
  }
  .mt-sm-22,
  .my-sm-22 {
    margin-top: 11.5rem !important;
  }
  .mr-sm-22,
  .mx-sm-22 {
    margin-right: 11.5rem !important;
  }
  .mb-sm-22,
  .my-sm-22 {
    margin-bottom: 11.5rem !important;
  }
  .ml-sm-22,
  .mx-sm-22 {
    margin-left: 11.5rem !important;
  }
  .m-sm-23 {
    margin: 12rem !important;
  }
  .mt-sm-23,
  .my-sm-23 {
    margin-top: 12rem !important;
  }
  .mr-sm-23,
  .mx-sm-23 {
    margin-right: 12rem !important;
  }
  .mb-sm-23,
  .my-sm-23 {
    margin-bottom: 12rem !important;
  }
  .ml-sm-23,
  .mx-sm-23 {
    margin-left: 12rem !important;
  }
  .m-sm-24 {
    margin: 12.5rem !important;
  }
  .mt-sm-24,
  .my-sm-24 {
    margin-top: 12.5rem !important;
  }
  .mr-sm-24,
  .mx-sm-24 {
    margin-right: 12.5rem !important;
  }
  .mb-sm-24,
  .my-sm-24 {
    margin-bottom: 12.5rem !important;
  }
  .ml-sm-24,
  .mx-sm-24 {
    margin-left: 12.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important;
  }
  .p-sm-6 {
    padding: 2.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 2.5rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3rem !important;
  }
  .p-sm-8 {
    padding: 3.5rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 3.5rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 3.5rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 3.5rem !important;
  }
  .p-sm-9 {
    padding: 4rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 4rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 4rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 4rem !important;
  }
  .p-sm-10 {
    padding: 4.5rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 4.5rem !important;
  }
  .p-sm-11 {
    padding: 5rem !important;
  }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 5rem !important;
  }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 5rem !important;
  }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 5rem !important;
  }
  .p-sm-12 {
    padding: 5.5rem !important;
  }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 5.5rem !important;
  }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 5.5rem !important;
  }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 5.5rem !important;
  }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 5.5rem !important;
  }
  .p-sm-13 {
    padding: 6rem !important;
  }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 6rem !important;
  }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 6rem !important;
  }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 6rem !important;
  }
  .p-sm-14 {
    padding: 6.5rem !important;
  }
  .pt-sm-14,
  .py-sm-14 {
    padding-top: 6.5rem !important;
  }
  .pr-sm-14,
  .px-sm-14 {
    padding-right: 6.5rem !important;
  }
  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 6.5rem !important;
  }
  .pl-sm-14,
  .px-sm-14 {
    padding-left: 6.5rem !important;
  }
  .p-sm-15 {
    padding: 7rem !important;
  }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 7rem !important;
  }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 7rem !important;
  }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 7rem !important;
  }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 7rem !important;
  }
  .p-sm-16 {
    padding: 7.5rem !important;
  }
  .pt-sm-16,
  .py-sm-16 {
    padding-top: 7.5rem !important;
  }
  .pr-sm-16,
  .px-sm-16 {
    padding-right: 7.5rem !important;
  }
  .pb-sm-16,
  .py-sm-16 {
    padding-bottom: 7.5rem !important;
  }
  .pl-sm-16,
  .px-sm-16 {
    padding-left: 7.5rem !important;
  }
  .p-sm-17 {
    padding: 8rem !important;
  }
  .pt-sm-17,
  .py-sm-17 {
    padding-top: 8rem !important;
  }
  .pr-sm-17,
  .px-sm-17 {
    padding-right: 8rem !important;
  }
  .pb-sm-17,
  .py-sm-17 {
    padding-bottom: 8rem !important;
  }
  .pl-sm-17,
  .px-sm-17 {
    padding-left: 8rem !important;
  }
  .p-sm-18 {
    padding: 8.5rem !important;
  }
  .pt-sm-18,
  .py-sm-18 {
    padding-top: 8.5rem !important;
  }
  .pr-sm-18,
  .px-sm-18 {
    padding-right: 8.5rem !important;
  }
  .pb-sm-18,
  .py-sm-18 {
    padding-bottom: 8.5rem !important;
  }
  .pl-sm-18,
  .px-sm-18 {
    padding-left: 8.5rem !important;
  }
  .p-sm-19 {
    padding: 9rem !important;
  }
  .pt-sm-19,
  .py-sm-19 {
    padding-top: 9rem !important;
  }
  .pr-sm-19,
  .px-sm-19 {
    padding-right: 9rem !important;
  }
  .pb-sm-19,
  .py-sm-19 {
    padding-bottom: 9rem !important;
  }
  .pl-sm-19,
  .px-sm-19 {
    padding-left: 9rem !important;
  }
  .p-sm-20 {
    padding: 9.5rem !important;
  }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 9.5rem !important;
  }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 9.5rem !important;
  }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 9.5rem !important;
  }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 9.5rem !important;
  }
  .p-sm-21 {
    padding: 10rem !important;
  }
  .pt-sm-21,
  .py-sm-21 {
    padding-top: 10rem !important;
  }
  .pr-sm-21,
  .px-sm-21 {
    padding-right: 10rem !important;
  }
  .pb-sm-21,
  .py-sm-21 {
    padding-bottom: 10rem !important;
  }
  .pl-sm-21,
  .px-sm-21 {
    padding-left: 10rem !important;
  }
  .p-sm-22 {
    padding: 11.5rem !important;
  }
  .pt-sm-22,
  .py-sm-22 {
    padding-top: 11.5rem !important;
  }
  .pr-sm-22,
  .px-sm-22 {
    padding-right: 11.5rem !important;
  }
  .pb-sm-22,
  .py-sm-22 {
    padding-bottom: 11.5rem !important;
  }
  .pl-sm-22,
  .px-sm-22 {
    padding-left: 11.5rem !important;
  }
  .p-sm-23 {
    padding: 12rem !important;
  }
  .pt-sm-23,
  .py-sm-23 {
    padding-top: 12rem !important;
  }
  .pr-sm-23,
  .px-sm-23 {
    padding-right: 12rem !important;
  }
  .pb-sm-23,
  .py-sm-23 {
    padding-bottom: 12rem !important;
  }
  .pl-sm-23,
  .px-sm-23 {
    padding-left: 12rem !important;
  }
  .p-sm-24 {
    padding: 12.5rem !important;
  }
  .pt-sm-24,
  .py-sm-24 {
    padding-top: 12.5rem !important;
  }
  .pr-sm-24,
  .px-sm-24 {
    padding-right: 12.5rem !important;
  }
  .pb-sm-24,
  .py-sm-24 {
    padding-bottom: 12.5rem !important;
  }
  .pl-sm-24,
  .px-sm-24 {
    padding-left: 12.5rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2rem !important;
  }
  .m-sm-n6 {
    margin: -2.5rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -2.5rem !important;
  }
  .m-sm-n7 {
    margin: -3rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -3rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -3rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -3rem !important;
  }
  .m-sm-n8 {
    margin: -3.5rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -3.5rem !important;
  }
  .m-sm-n9 {
    margin: -4rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -4rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -4rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -4rem !important;
  }
  .m-sm-n10 {
    margin: -4.5rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -4.5rem !important;
  }
  .m-sm-n11 {
    margin: -5rem !important;
  }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -5rem !important;
  }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -5rem !important;
  }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -5rem !important;
  }
  .m-sm-n12 {
    margin: -5.5rem !important;
  }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -5.5rem !important;
  }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -5.5rem !important;
  }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -5.5rem !important;
  }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -5.5rem !important;
  }
  .m-sm-n13 {
    margin: -6rem !important;
  }
  .mt-sm-n13,
  .my-sm-n13 {
    margin-top: -6rem !important;
  }
  .mr-sm-n13,
  .mx-sm-n13 {
    margin-right: -6rem !important;
  }
  .mb-sm-n13,
  .my-sm-n13 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n13,
  .mx-sm-n13 {
    margin-left: -6rem !important;
  }
  .m-sm-n14 {
    margin: -6.5rem !important;
  }
  .mt-sm-n14,
  .my-sm-n14 {
    margin-top: -6.5rem !important;
  }
  .mr-sm-n14,
  .mx-sm-n14 {
    margin-right: -6.5rem !important;
  }
  .mb-sm-n14,
  .my-sm-n14 {
    margin-bottom: -6.5rem !important;
  }
  .ml-sm-n14,
  .mx-sm-n14 {
    margin-left: -6.5rem !important;
  }
  .m-sm-n15 {
    margin: -7rem !important;
  }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -7rem !important;
  }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -7rem !important;
  }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -7rem !important;
  }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -7rem !important;
  }
  .m-sm-n16 {
    margin: -7.5rem !important;
  }
  .mt-sm-n16,
  .my-sm-n16 {
    margin-top: -7.5rem !important;
  }
  .mr-sm-n16,
  .mx-sm-n16 {
    margin-right: -7.5rem !important;
  }
  .mb-sm-n16,
  .my-sm-n16 {
    margin-bottom: -7.5rem !important;
  }
  .ml-sm-n16,
  .mx-sm-n16 {
    margin-left: -7.5rem !important;
  }
  .m-sm-n17 {
    margin: -8rem !important;
  }
  .mt-sm-n17,
  .my-sm-n17 {
    margin-top: -8rem !important;
  }
  .mr-sm-n17,
  .mx-sm-n17 {
    margin-right: -8rem !important;
  }
  .mb-sm-n17,
  .my-sm-n17 {
    margin-bottom: -8rem !important;
  }
  .ml-sm-n17,
  .mx-sm-n17 {
    margin-left: -8rem !important;
  }
  .m-sm-n18 {
    margin: -8.5rem !important;
  }
  .mt-sm-n18,
  .my-sm-n18 {
    margin-top: -8.5rem !important;
  }
  .mr-sm-n18,
  .mx-sm-n18 {
    margin-right: -8.5rem !important;
  }
  .mb-sm-n18,
  .my-sm-n18 {
    margin-bottom: -8.5rem !important;
  }
  .ml-sm-n18,
  .mx-sm-n18 {
    margin-left: -8.5rem !important;
  }
  .m-sm-n19 {
    margin: -9rem !important;
  }
  .mt-sm-n19,
  .my-sm-n19 {
    margin-top: -9rem !important;
  }
  .mr-sm-n19,
  .mx-sm-n19 {
    margin-right: -9rem !important;
  }
  .mb-sm-n19,
  .my-sm-n19 {
    margin-bottom: -9rem !important;
  }
  .ml-sm-n19,
  .mx-sm-n19 {
    margin-left: -9rem !important;
  }
  .m-sm-n20 {
    margin: -9.5rem !important;
  }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -9.5rem !important;
  }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -9.5rem !important;
  }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -9.5rem !important;
  }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -9.5rem !important;
  }
  .m-sm-n21 {
    margin: -10rem !important;
  }
  .mt-sm-n21,
  .my-sm-n21 {
    margin-top: -10rem !important;
  }
  .mr-sm-n21,
  .mx-sm-n21 {
    margin-right: -10rem !important;
  }
  .mb-sm-n21,
  .my-sm-n21 {
    margin-bottom: -10rem !important;
  }
  .ml-sm-n21,
  .mx-sm-n21 {
    margin-left: -10rem !important;
  }
  .m-sm-n22 {
    margin: -11.5rem !important;
  }
  .mt-sm-n22,
  .my-sm-n22 {
    margin-top: -11.5rem !important;
  }
  .mr-sm-n22,
  .mx-sm-n22 {
    margin-right: -11.5rem !important;
  }
  .mb-sm-n22,
  .my-sm-n22 {
    margin-bottom: -11.5rem !important;
  }
  .ml-sm-n22,
  .mx-sm-n22 {
    margin-left: -11.5rem !important;
  }
  .m-sm-n23 {
    margin: -12rem !important;
  }
  .mt-sm-n23,
  .my-sm-n23 {
    margin-top: -12rem !important;
  }
  .mr-sm-n23,
  .mx-sm-n23 {
    margin-right: -12rem !important;
  }
  .mb-sm-n23,
  .my-sm-n23 {
    margin-bottom: -12rem !important;
  }
  .ml-sm-n23,
  .mx-sm-n23 {
    margin-left: -12rem !important;
  }
  .m-sm-n24 {
    margin: -12.5rem !important;
  }
  .mt-sm-n24,
  .my-sm-n24 {
    margin-top: -12.5rem !important;
  }
  .mr-sm-n24,
  .mx-sm-n24 {
    margin-right: -12.5rem !important;
  }
  .mb-sm-n24,
  .my-sm-n24 {
    margin-bottom: -12.5rem !important;
  }
  .ml-sm-n24,
  .mx-sm-n24 {
    margin-left: -12.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2rem !important;
  }
  .m-md-6 {
    margin: 2.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 2.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 2.5rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3rem !important;
  }
  .m-md-8 {
    margin: 3.5rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 3.5rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 3.5rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 3.5rem !important;
  }
  .m-md-9 {
    margin: 4rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 4rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 4rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 4rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 4rem !important;
  }
  .m-md-10 {
    margin: 4.5rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 4.5rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 4.5rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 4.5rem !important;
  }
  .m-md-11 {
    margin: 5rem !important;
  }
  .mt-md-11,
  .my-md-11 {
    margin-top: 5rem !important;
  }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 5rem !important;
  }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 5rem !important;
  }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 5rem !important;
  }
  .m-md-12 {
    margin: 5.5rem !important;
  }
  .mt-md-12,
  .my-md-12 {
    margin-top: 5.5rem !important;
  }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 5.5rem !important;
  }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 5.5rem !important;
  }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 5.5rem !important;
  }
  .m-md-13 {
    margin: 6rem !important;
  }
  .mt-md-13,
  .my-md-13 {
    margin-top: 6rem !important;
  }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 6rem !important;
  }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 6rem !important;
  }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 6rem !important;
  }
  .m-md-14 {
    margin: 6.5rem !important;
  }
  .mt-md-14,
  .my-md-14 {
    margin-top: 6.5rem !important;
  }
  .mr-md-14,
  .mx-md-14 {
    margin-right: 6.5rem !important;
  }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 6.5rem !important;
  }
  .ml-md-14,
  .mx-md-14 {
    margin-left: 6.5rem !important;
  }
  .m-md-15 {
    margin: 7rem !important;
  }
  .mt-md-15,
  .my-md-15 {
    margin-top: 7rem !important;
  }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 7rem !important;
  }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 7rem !important;
  }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 7rem !important;
  }
  .m-md-16 {
    margin: 7.5rem !important;
  }
  .mt-md-16,
  .my-md-16 {
    margin-top: 7.5rem !important;
  }
  .mr-md-16,
  .mx-md-16 {
    margin-right: 7.5rem !important;
  }
  .mb-md-16,
  .my-md-16 {
    margin-bottom: 7.5rem !important;
  }
  .ml-md-16,
  .mx-md-16 {
    margin-left: 7.5rem !important;
  }
  .m-md-17 {
    margin: 8rem !important;
  }
  .mt-md-17,
  .my-md-17 {
    margin-top: 8rem !important;
  }
  .mr-md-17,
  .mx-md-17 {
    margin-right: 8rem !important;
  }
  .mb-md-17,
  .my-md-17 {
    margin-bottom: 8rem !important;
  }
  .ml-md-17,
  .mx-md-17 {
    margin-left: 8rem !important;
  }
  .m-md-18 {
    margin: 8.5rem !important;
  }
  .mt-md-18,
  .my-md-18 {
    margin-top: 8.5rem !important;
  }
  .mr-md-18,
  .mx-md-18 {
    margin-right: 8.5rem !important;
  }
  .mb-md-18,
  .my-md-18 {
    margin-bottom: 8.5rem !important;
  }
  .ml-md-18,
  .mx-md-18 {
    margin-left: 8.5rem !important;
  }
  .m-md-19 {
    margin: 9rem !important;
  }
  .mt-md-19,
  .my-md-19 {
    margin-top: 9rem !important;
  }
  .mr-md-19,
  .mx-md-19 {
    margin-right: 9rem !important;
  }
  .mb-md-19,
  .my-md-19 {
    margin-bottom: 9rem !important;
  }
  .ml-md-19,
  .mx-md-19 {
    margin-left: 9rem !important;
  }
  .m-md-20 {
    margin: 9.5rem !important;
  }
  .mt-md-20,
  .my-md-20 {
    margin-top: 9.5rem !important;
  }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 9.5rem !important;
  }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 9.5rem !important;
  }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 9.5rem !important;
  }
  .m-md-21 {
    margin: 10rem !important;
  }
  .mt-md-21,
  .my-md-21 {
    margin-top: 10rem !important;
  }
  .mr-md-21,
  .mx-md-21 {
    margin-right: 10rem !important;
  }
  .mb-md-21,
  .my-md-21 {
    margin-bottom: 10rem !important;
  }
  .ml-md-21,
  .mx-md-21 {
    margin-left: 10rem !important;
  }
  .m-md-22 {
    margin: 11.5rem !important;
  }
  .mt-md-22,
  .my-md-22 {
    margin-top: 11.5rem !important;
  }
  .mr-md-22,
  .mx-md-22 {
    margin-right: 11.5rem !important;
  }
  .mb-md-22,
  .my-md-22 {
    margin-bottom: 11.5rem !important;
  }
  .ml-md-22,
  .mx-md-22 {
    margin-left: 11.5rem !important;
  }
  .m-md-23 {
    margin: 12rem !important;
  }
  .mt-md-23,
  .my-md-23 {
    margin-top: 12rem !important;
  }
  .mr-md-23,
  .mx-md-23 {
    margin-right: 12rem !important;
  }
  .mb-md-23,
  .my-md-23 {
    margin-bottom: 12rem !important;
  }
  .ml-md-23,
  .mx-md-23 {
    margin-left: 12rem !important;
  }
  .m-md-24 {
    margin: 12.5rem !important;
  }
  .mt-md-24,
  .my-md-24 {
    margin-top: 12.5rem !important;
  }
  .mr-md-24,
  .mx-md-24 {
    margin-right: 12.5rem !important;
  }
  .mb-md-24,
  .my-md-24 {
    margin-bottom: 12.5rem !important;
  }
  .ml-md-24,
  .mx-md-24 {
    margin-left: 12.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important;
  }
  .p-md-6 {
    padding: 2.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 2.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 2.5rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3rem !important;
  }
  .p-md-8 {
    padding: 3.5rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 3.5rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 3.5rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 3.5rem !important;
  }
  .p-md-9 {
    padding: 4rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 4rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 4rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 4rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 4rem !important;
  }
  .p-md-10 {
    padding: 4.5rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 4.5rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 4.5rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 4.5rem !important;
  }
  .p-md-11 {
    padding: 5rem !important;
  }
  .pt-md-11,
  .py-md-11 {
    padding-top: 5rem !important;
  }
  .pr-md-11,
  .px-md-11 {
    padding-right: 5rem !important;
  }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 5rem !important;
  }
  .pl-md-11,
  .px-md-11 {
    padding-left: 5rem !important;
  }
  .p-md-12 {
    padding: 5.5rem !important;
  }
  .pt-md-12,
  .py-md-12 {
    padding-top: 5.5rem !important;
  }
  .pr-md-12,
  .px-md-12 {
    padding-right: 5.5rem !important;
  }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 5.5rem !important;
  }
  .pl-md-12,
  .px-md-12 {
    padding-left: 5.5rem !important;
  }
  .p-md-13 {
    padding: 6rem !important;
  }
  .pt-md-13,
  .py-md-13 {
    padding-top: 6rem !important;
  }
  .pr-md-13,
  .px-md-13 {
    padding-right: 6rem !important;
  }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 6rem !important;
  }
  .pl-md-13,
  .px-md-13 {
    padding-left: 6rem !important;
  }
  .p-md-14 {
    padding: 6.5rem !important;
  }
  .pt-md-14,
  .py-md-14 {
    padding-top: 6.5rem !important;
  }
  .pr-md-14,
  .px-md-14 {
    padding-right: 6.5rem !important;
  }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 6.5rem !important;
  }
  .pl-md-14,
  .px-md-14 {
    padding-left: 6.5rem !important;
  }
  .p-md-15 {
    padding: 7rem !important;
  }
  .pt-md-15,
  .py-md-15 {
    padding-top: 7rem !important;
  }
  .pr-md-15,
  .px-md-15 {
    padding-right: 7rem !important;
  }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 7rem !important;
  }
  .pl-md-15,
  .px-md-15 {
    padding-left: 7rem !important;
  }
  .p-md-16 {
    padding: 7.5rem !important;
  }
  .pt-md-16,
  .py-md-16 {
    padding-top: 7.5rem !important;
  }
  .pr-md-16,
  .px-md-16 {
    padding-right: 7.5rem !important;
  }
  .pb-md-16,
  .py-md-16 {
    padding-bottom: 7.5rem !important;
  }
  .pl-md-16,
  .px-md-16 {
    padding-left: 7.5rem !important;
  }
  .p-md-17 {
    padding: 8rem !important;
  }
  .pt-md-17,
  .py-md-17 {
    padding-top: 8rem !important;
  }
  .pr-md-17,
  .px-md-17 {
    padding-right: 8rem !important;
  }
  .pb-md-17,
  .py-md-17 {
    padding-bottom: 8rem !important;
  }
  .pl-md-17,
  .px-md-17 {
    padding-left: 8rem !important;
  }
  .p-md-18 {
    padding: 8.5rem !important;
  }
  .pt-md-18,
  .py-md-18 {
    padding-top: 8.5rem !important;
  }
  .pr-md-18,
  .px-md-18 {
    padding-right: 8.5rem !important;
  }
  .pb-md-18,
  .py-md-18 {
    padding-bottom: 8.5rem !important;
  }
  .pl-md-18,
  .px-md-18 {
    padding-left: 8.5rem !important;
  }
  .p-md-19 {
    padding: 9rem !important;
  }
  .pt-md-19,
  .py-md-19 {
    padding-top: 9rem !important;
  }
  .pr-md-19,
  .px-md-19 {
    padding-right: 9rem !important;
  }
  .pb-md-19,
  .py-md-19 {
    padding-bottom: 9rem !important;
  }
  .pl-md-19,
  .px-md-19 {
    padding-left: 9rem !important;
  }
  .p-md-20 {
    padding: 9.5rem !important;
  }
  .pt-md-20,
  .py-md-20 {
    padding-top: 9.5rem !important;
  }
  .pr-md-20,
  .px-md-20 {
    padding-right: 9.5rem !important;
  }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 9.5rem !important;
  }
  .pl-md-20,
  .px-md-20 {
    padding-left: 9.5rem !important;
  }
  .p-md-21 {
    padding: 10rem !important;
  }
  .pt-md-21,
  .py-md-21 {
    padding-top: 10rem !important;
  }
  .pr-md-21,
  .px-md-21 {
    padding-right: 10rem !important;
  }
  .pb-md-21,
  .py-md-21 {
    padding-bottom: 10rem !important;
  }
  .pl-md-21,
  .px-md-21 {
    padding-left: 10rem !important;
  }
  .p-md-22 {
    padding: 11.5rem !important;
  }
  .pt-md-22,
  .py-md-22 {
    padding-top: 11.5rem !important;
  }
  .pr-md-22,
  .px-md-22 {
    padding-right: 11.5rem !important;
  }
  .pb-md-22,
  .py-md-22 {
    padding-bottom: 11.5rem !important;
  }
  .pl-md-22,
  .px-md-22 {
    padding-left: 11.5rem !important;
  }
  .p-md-23 {
    padding: 12rem !important;
  }
  .pt-md-23,
  .py-md-23 {
    padding-top: 12rem !important;
  }
  .pr-md-23,
  .px-md-23 {
    padding-right: 12rem !important;
  }
  .pb-md-23,
  .py-md-23 {
    padding-bottom: 12rem !important;
  }
  .pl-md-23,
  .px-md-23 {
    padding-left: 12rem !important;
  }
  .p-md-24 {
    padding: 12.5rem !important;
  }
  .pt-md-24,
  .py-md-24 {
    padding-top: 12.5rem !important;
  }
  .pr-md-24,
  .px-md-24 {
    padding-right: 12.5rem !important;
  }
  .pb-md-24,
  .py-md-24 {
    padding-bottom: 12.5rem !important;
  }
  .pl-md-24,
  .px-md-24 {
    padding-left: 12.5rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2rem !important;
  }
  .m-md-n6 {
    margin: -2.5rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -2.5rem !important;
  }
  .m-md-n7 {
    margin: -3rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -3rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -3rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -3rem !important;
  }
  .m-md-n8 {
    margin: -3.5rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -3.5rem !important;
  }
  .m-md-n9 {
    margin: -4rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -4rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -4rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -4rem !important;
  }
  .m-md-n10 {
    margin: -4.5rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -4.5rem !important;
  }
  .m-md-n11 {
    margin: -5rem !important;
  }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -5rem !important;
  }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -5rem !important;
  }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -5rem !important;
  }
  .m-md-n12 {
    margin: -5.5rem !important;
  }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -5.5rem !important;
  }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -5.5rem !important;
  }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -5.5rem !important;
  }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -5.5rem !important;
  }
  .m-md-n13 {
    margin: -6rem !important;
  }
  .mt-md-n13,
  .my-md-n13 {
    margin-top: -6rem !important;
  }
  .mr-md-n13,
  .mx-md-n13 {
    margin-right: -6rem !important;
  }
  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n13,
  .mx-md-n13 {
    margin-left: -6rem !important;
  }
  .m-md-n14 {
    margin: -6.5rem !important;
  }
  .mt-md-n14,
  .my-md-n14 {
    margin-top: -6.5rem !important;
  }
  .mr-md-n14,
  .mx-md-n14 {
    margin-right: -6.5rem !important;
  }
  .mb-md-n14,
  .my-md-n14 {
    margin-bottom: -6.5rem !important;
  }
  .ml-md-n14,
  .mx-md-n14 {
    margin-left: -6.5rem !important;
  }
  .m-md-n15 {
    margin: -7rem !important;
  }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -7rem !important;
  }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -7rem !important;
  }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -7rem !important;
  }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -7rem !important;
  }
  .m-md-n16 {
    margin: -7.5rem !important;
  }
  .mt-md-n16,
  .my-md-n16 {
    margin-top: -7.5rem !important;
  }
  .mr-md-n16,
  .mx-md-n16 {
    margin-right: -7.5rem !important;
  }
  .mb-md-n16,
  .my-md-n16 {
    margin-bottom: -7.5rem !important;
  }
  .ml-md-n16,
  .mx-md-n16 {
    margin-left: -7.5rem !important;
  }
  .m-md-n17 {
    margin: -8rem !important;
  }
  .mt-md-n17,
  .my-md-n17 {
    margin-top: -8rem !important;
  }
  .mr-md-n17,
  .mx-md-n17 {
    margin-right: -8rem !important;
  }
  .mb-md-n17,
  .my-md-n17 {
    margin-bottom: -8rem !important;
  }
  .ml-md-n17,
  .mx-md-n17 {
    margin-left: -8rem !important;
  }
  .m-md-n18 {
    margin: -8.5rem !important;
  }
  .mt-md-n18,
  .my-md-n18 {
    margin-top: -8.5rem !important;
  }
  .mr-md-n18,
  .mx-md-n18 {
    margin-right: -8.5rem !important;
  }
  .mb-md-n18,
  .my-md-n18 {
    margin-bottom: -8.5rem !important;
  }
  .ml-md-n18,
  .mx-md-n18 {
    margin-left: -8.5rem !important;
  }
  .m-md-n19 {
    margin: -9rem !important;
  }
  .mt-md-n19,
  .my-md-n19 {
    margin-top: -9rem !important;
  }
  .mr-md-n19,
  .mx-md-n19 {
    margin-right: -9rem !important;
  }
  .mb-md-n19,
  .my-md-n19 {
    margin-bottom: -9rem !important;
  }
  .ml-md-n19,
  .mx-md-n19 {
    margin-left: -9rem !important;
  }
  .m-md-n20 {
    margin: -9.5rem !important;
  }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -9.5rem !important;
  }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -9.5rem !important;
  }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -9.5rem !important;
  }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -9.5rem !important;
  }
  .m-md-n21 {
    margin: -10rem !important;
  }
  .mt-md-n21,
  .my-md-n21 {
    margin-top: -10rem !important;
  }
  .mr-md-n21,
  .mx-md-n21 {
    margin-right: -10rem !important;
  }
  .mb-md-n21,
  .my-md-n21 {
    margin-bottom: -10rem !important;
  }
  .ml-md-n21,
  .mx-md-n21 {
    margin-left: -10rem !important;
  }
  .m-md-n22 {
    margin: -11.5rem !important;
  }
  .mt-md-n22,
  .my-md-n22 {
    margin-top: -11.5rem !important;
  }
  .mr-md-n22,
  .mx-md-n22 {
    margin-right: -11.5rem !important;
  }
  .mb-md-n22,
  .my-md-n22 {
    margin-bottom: -11.5rem !important;
  }
  .ml-md-n22,
  .mx-md-n22 {
    margin-left: -11.5rem !important;
  }
  .m-md-n23 {
    margin: -12rem !important;
  }
  .mt-md-n23,
  .my-md-n23 {
    margin-top: -12rem !important;
  }
  .mr-md-n23,
  .mx-md-n23 {
    margin-right: -12rem !important;
  }
  .mb-md-n23,
  .my-md-n23 {
    margin-bottom: -12rem !important;
  }
  .ml-md-n23,
  .mx-md-n23 {
    margin-left: -12rem !important;
  }
  .m-md-n24 {
    margin: -12.5rem !important;
  }
  .mt-md-n24,
  .my-md-n24 {
    margin-top: -12.5rem !important;
  }
  .mr-md-n24,
  .mx-md-n24 {
    margin-right: -12.5rem !important;
  }
  .mb-md-n24,
  .my-md-n24 {
    margin-bottom: -12.5rem !important;
  }
  .ml-md-n24,
  .mx-md-n24 {
    margin-left: -12.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2rem !important;
  }
  .m-lg-6 {
    margin: 2.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 2.5rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3rem !important;
  }
  .m-lg-8 {
    margin: 3.5rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 3.5rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 3.5rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 3.5rem !important;
  }
  .m-lg-9 {
    margin: 4rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 4rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 4rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 4rem !important;
  }
  .m-lg-10 {
    margin: 4.5rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 4.5rem !important;
  }
  .m-lg-11 {
    margin: 5rem !important;
  }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 5rem !important;
  }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 5rem !important;
  }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 5rem !important;
  }
  .m-lg-12 {
    margin: 5.5rem !important;
  }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 5.5rem !important;
  }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 5.5rem !important;
  }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 5.5rem !important;
  }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 5.5rem !important;
  }
  .m-lg-13 {
    margin: 6rem !important;
  }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 6rem !important;
  }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 6rem !important;
  }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 6rem !important;
  }
  .m-lg-14 {
    margin: 6.5rem !important;
  }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 6.5rem !important;
  }
  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 6.5rem !important;
  }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 6.5rem !important;
  }
  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 6.5rem !important;
  }
  .m-lg-15 {
    margin: 7rem !important;
  }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 7rem !important;
  }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 7rem !important;
  }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 7rem !important;
  }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 7rem !important;
  }
  .m-lg-16 {
    margin: 7.5rem !important;
  }
  .mt-lg-16,
  .my-lg-16 {
    margin-top: 7.5rem !important;
  }
  .mr-lg-16,
  .mx-lg-16 {
    margin-right: 7.5rem !important;
  }
  .mb-lg-16,
  .my-lg-16 {
    margin-bottom: 7.5rem !important;
  }
  .ml-lg-16,
  .mx-lg-16 {
    margin-left: 7.5rem !important;
  }
  .m-lg-17 {
    margin: 8rem !important;
  }
  .mt-lg-17,
  .my-lg-17 {
    margin-top: 8rem !important;
  }
  .mr-lg-17,
  .mx-lg-17 {
    margin-right: 8rem !important;
  }
  .mb-lg-17,
  .my-lg-17 {
    margin-bottom: 8rem !important;
  }
  .ml-lg-17,
  .mx-lg-17 {
    margin-left: 8rem !important;
  }
  .m-lg-18 {
    margin: 8.5rem !important;
  }
  .mt-lg-18,
  .my-lg-18 {
    margin-top: 8.5rem !important;
  }
  .mr-lg-18,
  .mx-lg-18 {
    margin-right: 8.5rem !important;
  }
  .mb-lg-18,
  .my-lg-18 {
    margin-bottom: 8.5rem !important;
  }
  .ml-lg-18,
  .mx-lg-18 {
    margin-left: 8.5rem !important;
  }
  .m-lg-19 {
    margin: 9rem !important;
  }
  .mt-lg-19,
  .my-lg-19 {
    margin-top: 9rem !important;
  }
  .mr-lg-19,
  .mx-lg-19 {
    margin-right: 9rem !important;
  }
  .mb-lg-19,
  .my-lg-19 {
    margin-bottom: 9rem !important;
  }
  .ml-lg-19,
  .mx-lg-19 {
    margin-left: 9rem !important;
  }
  .m-lg-20 {
    margin: 9.5rem !important;
  }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 9.5rem !important;
  }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 9.5rem !important;
  }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 9.5rem !important;
  }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 9.5rem !important;
  }
  .m-lg-21 {
    margin: 10rem !important;
  }
  .mt-lg-21,
  .my-lg-21 {
    margin-top: 10rem !important;
  }
  .mr-lg-21,
  .mx-lg-21 {
    margin-right: 10rem !important;
  }
  .mb-lg-21,
  .my-lg-21 {
    margin-bottom: 10rem !important;
  }
  .ml-lg-21,
  .mx-lg-21 {
    margin-left: 10rem !important;
  }
  .m-lg-22 {
    margin: 11.5rem !important;
  }
  .mt-lg-22,
  .my-lg-22 {
    margin-top: 11.5rem !important;
  }
  .mr-lg-22,
  .mx-lg-22 {
    margin-right: 11.5rem !important;
  }
  .mb-lg-22,
  .my-lg-22 {
    margin-bottom: 11.5rem !important;
  }
  .ml-lg-22,
  .mx-lg-22 {
    margin-left: 11.5rem !important;
  }
  .m-lg-23 {
    margin: 12rem !important;
  }
  .mt-lg-23,
  .my-lg-23 {
    margin-top: 12rem !important;
  }
  .mr-lg-23,
  .mx-lg-23 {
    margin-right: 12rem !important;
  }
  .mb-lg-23,
  .my-lg-23 {
    margin-bottom: 12rem !important;
  }
  .ml-lg-23,
  .mx-lg-23 {
    margin-left: 12rem !important;
  }
  .m-lg-24 {
    margin: 12.5rem !important;
  }
  .mt-lg-24,
  .my-lg-24 {
    margin-top: 12.5rem !important;
  }
  .mr-lg-24,
  .mx-lg-24 {
    margin-right: 12.5rem !important;
  }
  .mb-lg-24,
  .my-lg-24 {
    margin-bottom: 12.5rem !important;
  }
  .ml-lg-24,
  .mx-lg-24 {
    margin-left: 12.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important;
  }
  .p-lg-6 {
    padding: 2.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 2.5rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3rem !important;
  }
  .p-lg-8 {
    padding: 3.5rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 3.5rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 3.5rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 3.5rem !important;
  }
  .p-lg-9 {
    padding: 4rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 4rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 4rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 4rem !important;
  }
  .p-lg-10 {
    padding: 4.5rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 4.5rem !important;
  }
  .p-lg-11 {
    padding: 5rem !important;
  }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 5rem !important;
  }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 5rem !important;
  }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 5rem !important;
  }
  .p-lg-12 {
    padding: 5.5rem !important;
  }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 5.5rem !important;
  }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 5.5rem !important;
  }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 5.5rem !important;
  }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 5.5rem !important;
  }
  .p-lg-13 {
    padding: 6rem !important;
  }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 6rem !important;
  }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 6rem !important;
  }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 6rem !important;
  }
  .p-lg-14 {
    padding: 6.5rem !important;
  }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 6.5rem !important;
  }
  .pr-lg-14,
  .px-lg-14 {
    padding-right: 6.5rem !important;
  }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 6.5rem !important;
  }
  .pl-lg-14,
  .px-lg-14 {
    padding-left: 6.5rem !important;
  }
  .p-lg-15 {
    padding: 7rem !important;
  }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 7rem !important;
  }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 7rem !important;
  }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 7rem !important;
  }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 7rem !important;
  }
  .p-lg-16 {
    padding: 7.5rem !important;
  }
  .pt-lg-16,
  .py-lg-16 {
    padding-top: 7.5rem !important;
  }
  .pr-lg-16,
  .px-lg-16 {
    padding-right: 7.5rem !important;
  }
  .pb-lg-16,
  .py-lg-16 {
    padding-bottom: 7.5rem !important;
  }
  .pl-lg-16,
  .px-lg-16 {
    padding-left: 7.5rem !important;
  }
  .p-lg-17 {
    padding: 8rem !important;
  }
  .pt-lg-17,
  .py-lg-17 {
    padding-top: 8rem !important;
  }
  .pr-lg-17,
  .px-lg-17 {
    padding-right: 8rem !important;
  }
  .pb-lg-17,
  .py-lg-17 {
    padding-bottom: 8rem !important;
  }
  .pl-lg-17,
  .px-lg-17 {
    padding-left: 8rem !important;
  }
  .p-lg-18 {
    padding: 8.5rem !important;
  }
  .pt-lg-18,
  .py-lg-18 {
    padding-top: 8.5rem !important;
  }
  .pr-lg-18,
  .px-lg-18 {
    padding-right: 8.5rem !important;
  }
  .pb-lg-18,
  .py-lg-18 {
    padding-bottom: 8.5rem !important;
  }
  .pl-lg-18,
  .px-lg-18 {
    padding-left: 8.5rem !important;
  }
  .p-lg-19 {
    padding: 9rem !important;
  }
  .pt-lg-19,
  .py-lg-19 {
    padding-top: 9rem !important;
  }
  .pr-lg-19,
  .px-lg-19 {
    padding-right: 9rem !important;
  }
  .pb-lg-19,
  .py-lg-19 {
    padding-bottom: 9rem !important;
  }
  .pl-lg-19,
  .px-lg-19 {
    padding-left: 9rem !important;
  }
  .p-lg-20 {
    padding: 9.5rem !important;
  }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 9.5rem !important;
  }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 9.5rem !important;
  }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 9.5rem !important;
  }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 9.5rem !important;
  }
  .p-lg-21 {
    padding: 10rem !important;
  }
  .pt-lg-21,
  .py-lg-21 {
    padding-top: 10rem !important;
  }
  .pr-lg-21,
  .px-lg-21 {
    padding-right: 10rem !important;
  }
  .pb-lg-21,
  .py-lg-21 {
    padding-bottom: 10rem !important;
  }
  .pl-lg-21,
  .px-lg-21 {
    padding-left: 10rem !important;
  }
  .p-lg-22 {
    padding: 11.5rem !important;
  }
  .pt-lg-22,
  .py-lg-22 {
    padding-top: 11.5rem !important;
  }
  .pr-lg-22,
  .px-lg-22 {
    padding-right: 11.5rem !important;
  }
  .pb-lg-22,
  .py-lg-22 {
    padding-bottom: 11.5rem !important;
  }
  .pl-lg-22,
  .px-lg-22 {
    padding-left: 11.5rem !important;
  }
  .p-lg-23 {
    padding: 12rem !important;
  }
  .pt-lg-23,
  .py-lg-23 {
    padding-top: 12rem !important;
  }
  .pr-lg-23,
  .px-lg-23 {
    padding-right: 12rem !important;
  }
  .pb-lg-23,
  .py-lg-23 {
    padding-bottom: 12rem !important;
  }
  .pl-lg-23,
  .px-lg-23 {
    padding-left: 12rem !important;
  }
  .p-lg-24 {
    padding: 12.5rem !important;
  }
  .pt-lg-24,
  .py-lg-24 {
    padding-top: 12.5rem !important;
  }
  .pr-lg-24,
  .px-lg-24 {
    padding-right: 12.5rem !important;
  }
  .pb-lg-24,
  .py-lg-24 {
    padding-bottom: 12.5rem !important;
  }
  .pl-lg-24,
  .px-lg-24 {
    padding-left: 12.5rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2rem !important;
  }
  .m-lg-n6 {
    margin: -2.5rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -2.5rem !important;
  }
  .m-lg-n7 {
    margin: -3rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -3rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -3rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -3rem !important;
  }
  .m-lg-n8 {
    margin: -3.5rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -3.5rem !important;
  }
  .m-lg-n9 {
    margin: -4rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -4rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -4rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -4rem !important;
  }
  .m-lg-n10 {
    margin: -4.5rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -4.5rem !important;
  }
  .m-lg-n11 {
    margin: -5rem !important;
  }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -5rem !important;
  }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -5rem !important;
  }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -5rem !important;
  }
  .m-lg-n12 {
    margin: -5.5rem !important;
  }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -5.5rem !important;
  }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -5.5rem !important;
  }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -5.5rem !important;
  }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -5.5rem !important;
  }
  .m-lg-n13 {
    margin: -6rem !important;
  }
  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -6rem !important;
  }
  .mr-lg-n13,
  .mx-lg-n13 {
    margin-right: -6rem !important;
  }
  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n13,
  .mx-lg-n13 {
    margin-left: -6rem !important;
  }
  .m-lg-n14 {
    margin: -6.5rem !important;
  }
  .mt-lg-n14,
  .my-lg-n14 {
    margin-top: -6.5rem !important;
  }
  .mr-lg-n14,
  .mx-lg-n14 {
    margin-right: -6.5rem !important;
  }
  .mb-lg-n14,
  .my-lg-n14 {
    margin-bottom: -6.5rem !important;
  }
  .ml-lg-n14,
  .mx-lg-n14 {
    margin-left: -6.5rem !important;
  }
  .m-lg-n15 {
    margin: -7rem !important;
  }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -7rem !important;
  }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -7rem !important;
  }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -7rem !important;
  }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -7rem !important;
  }
  .m-lg-n16 {
    margin: -7.5rem !important;
  }
  .mt-lg-n16,
  .my-lg-n16 {
    margin-top: -7.5rem !important;
  }
  .mr-lg-n16,
  .mx-lg-n16 {
    margin-right: -7.5rem !important;
  }
  .mb-lg-n16,
  .my-lg-n16 {
    margin-bottom: -7.5rem !important;
  }
  .ml-lg-n16,
  .mx-lg-n16 {
    margin-left: -7.5rem !important;
  }
  .m-lg-n17 {
    margin: -8rem !important;
  }
  .mt-lg-n17,
  .my-lg-n17 {
    margin-top: -8rem !important;
  }
  .mr-lg-n17,
  .mx-lg-n17 {
    margin-right: -8rem !important;
  }
  .mb-lg-n17,
  .my-lg-n17 {
    margin-bottom: -8rem !important;
  }
  .ml-lg-n17,
  .mx-lg-n17 {
    margin-left: -8rem !important;
  }
  .m-lg-n18 {
    margin: -8.5rem !important;
  }
  .mt-lg-n18,
  .my-lg-n18 {
    margin-top: -8.5rem !important;
  }
  .mr-lg-n18,
  .mx-lg-n18 {
    margin-right: -8.5rem !important;
  }
  .mb-lg-n18,
  .my-lg-n18 {
    margin-bottom: -8.5rem !important;
  }
  .ml-lg-n18,
  .mx-lg-n18 {
    margin-left: -8.5rem !important;
  }
  .m-lg-n19 {
    margin: -9rem !important;
  }
  .mt-lg-n19,
  .my-lg-n19 {
    margin-top: -9rem !important;
  }
  .mr-lg-n19,
  .mx-lg-n19 {
    margin-right: -9rem !important;
  }
  .mb-lg-n19,
  .my-lg-n19 {
    margin-bottom: -9rem !important;
  }
  .ml-lg-n19,
  .mx-lg-n19 {
    margin-left: -9rem !important;
  }
  .m-lg-n20 {
    margin: -9.5rem !important;
  }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -9.5rem !important;
  }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -9.5rem !important;
  }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -9.5rem !important;
  }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -9.5rem !important;
  }
  .m-lg-n21 {
    margin: -10rem !important;
  }
  .mt-lg-n21,
  .my-lg-n21 {
    margin-top: -10rem !important;
  }
  .mr-lg-n21,
  .mx-lg-n21 {
    margin-right: -10rem !important;
  }
  .mb-lg-n21,
  .my-lg-n21 {
    margin-bottom: -10rem !important;
  }
  .ml-lg-n21,
  .mx-lg-n21 {
    margin-left: -10rem !important;
  }
  .m-lg-n22 {
    margin: -11.5rem !important;
  }
  .mt-lg-n22,
  .my-lg-n22 {
    margin-top: -11.5rem !important;
  }
  .mr-lg-n22,
  .mx-lg-n22 {
    margin-right: -11.5rem !important;
  }
  .mb-lg-n22,
  .my-lg-n22 {
    margin-bottom: -11.5rem !important;
  }
  .ml-lg-n22,
  .mx-lg-n22 {
    margin-left: -11.5rem !important;
  }
  .m-lg-n23 {
    margin: -12rem !important;
  }
  .mt-lg-n23,
  .my-lg-n23 {
    margin-top: -12rem !important;
  }
  .mr-lg-n23,
  .mx-lg-n23 {
    margin-right: -12rem !important;
  }
  .mb-lg-n23,
  .my-lg-n23 {
    margin-bottom: -12rem !important;
  }
  .ml-lg-n23,
  .mx-lg-n23 {
    margin-left: -12rem !important;
  }
  .m-lg-n24 {
    margin: -12.5rem !important;
  }
  .mt-lg-n24,
  .my-lg-n24 {
    margin-top: -12.5rem !important;
  }
  .mr-lg-n24,
  .mx-lg-n24 {
    margin-right: -12.5rem !important;
  }
  .mb-lg-n24,
  .my-lg-n24 {
    margin-bottom: -12.5rem !important;
  }
  .ml-lg-n24,
  .mx-lg-n24 {
    margin-left: -12.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2rem !important;
  }
  .m-xl-6 {
    margin: 2.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 2.5rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3rem !important;
  }
  .m-xl-8 {
    margin: 3.5rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 3.5rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 3.5rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 3.5rem !important;
  }
  .m-xl-9 {
    margin: 4rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 4rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 4rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 4rem !important;
  }
  .m-xl-10 {
    margin: 4.5rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 4.5rem !important;
  }
  .m-xl-11 {
    margin: 5rem !important;
  }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 5rem !important;
  }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 5rem !important;
  }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 5rem !important;
  }
  .m-xl-12 {
    margin: 5.5rem !important;
  }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 5.5rem !important;
  }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 5.5rem !important;
  }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 5.5rem !important;
  }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 5.5rem !important;
  }
  .m-xl-13 {
    margin: 6rem !important;
  }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 6rem !important;
  }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 6rem !important;
  }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 6rem !important;
  }
  .m-xl-14 {
    margin: 6.5rem !important;
  }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 6.5rem !important;
  }
  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 6.5rem !important;
  }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 6.5rem !important;
  }
  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 6.5rem !important;
  }
  .m-xl-15 {
    margin: 7rem !important;
  }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 7rem !important;
  }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 7rem !important;
  }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 7rem !important;
  }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 7rem !important;
  }
  .m-xl-16 {
    margin: 7.5rem !important;
  }
  .mt-xl-16,
  .my-xl-16 {
    margin-top: 7.5rem !important;
  }
  .mr-xl-16,
  .mx-xl-16 {
    margin-right: 7.5rem !important;
  }
  .mb-xl-16,
  .my-xl-16 {
    margin-bottom: 7.5rem !important;
  }
  .ml-xl-16,
  .mx-xl-16 {
    margin-left: 7.5rem !important;
  }
  .m-xl-17 {
    margin: 8rem !important;
  }
  .mt-xl-17,
  .my-xl-17 {
    margin-top: 8rem !important;
  }
  .mr-xl-17,
  .mx-xl-17 {
    margin-right: 8rem !important;
  }
  .mb-xl-17,
  .my-xl-17 {
    margin-bottom: 8rem !important;
  }
  .ml-xl-17,
  .mx-xl-17 {
    margin-left: 8rem !important;
  }
  .m-xl-18 {
    margin: 8.5rem !important;
  }
  .mt-xl-18,
  .my-xl-18 {
    margin-top: 8.5rem !important;
  }
  .mr-xl-18,
  .mx-xl-18 {
    margin-right: 8.5rem !important;
  }
  .mb-xl-18,
  .my-xl-18 {
    margin-bottom: 8.5rem !important;
  }
  .ml-xl-18,
  .mx-xl-18 {
    margin-left: 8.5rem !important;
  }
  .m-xl-19 {
    margin: 9rem !important;
  }
  .mt-xl-19,
  .my-xl-19 {
    margin-top: 9rem !important;
  }
  .mr-xl-19,
  .mx-xl-19 {
    margin-right: 9rem !important;
  }
  .mb-xl-19,
  .my-xl-19 {
    margin-bottom: 9rem !important;
  }
  .ml-xl-19,
  .mx-xl-19 {
    margin-left: 9rem !important;
  }
  .m-xl-20 {
    margin: 9.5rem !important;
  }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 9.5rem !important;
  }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 9.5rem !important;
  }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 9.5rem !important;
  }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 9.5rem !important;
  }
  .m-xl-21 {
    margin: 10rem !important;
  }
  .mt-xl-21,
  .my-xl-21 {
    margin-top: 10rem !important;
  }
  .mr-xl-21,
  .mx-xl-21 {
    margin-right: 10rem !important;
  }
  .mb-xl-21,
  .my-xl-21 {
    margin-bottom: 10rem !important;
  }
  .ml-xl-21,
  .mx-xl-21 {
    margin-left: 10rem !important;
  }
  .m-xl-22 {
    margin: 11.5rem !important;
  }
  .mt-xl-22,
  .my-xl-22 {
    margin-top: 11.5rem !important;
  }
  .mr-xl-22,
  .mx-xl-22 {
    margin-right: 11.5rem !important;
  }
  .mb-xl-22,
  .my-xl-22 {
    margin-bottom: 11.5rem !important;
  }
  .ml-xl-22,
  .mx-xl-22 {
    margin-left: 11.5rem !important;
  }
  .m-xl-23 {
    margin: 12rem !important;
  }
  .mt-xl-23,
  .my-xl-23 {
    margin-top: 12rem !important;
  }
  .mr-xl-23,
  .mx-xl-23 {
    margin-right: 12rem !important;
  }
  .mb-xl-23,
  .my-xl-23 {
    margin-bottom: 12rem !important;
  }
  .ml-xl-23,
  .mx-xl-23 {
    margin-left: 12rem !important;
  }
  .m-xl-24 {
    margin: 12.5rem !important;
  }
  .mt-xl-24,
  .my-xl-24 {
    margin-top: 12.5rem !important;
  }
  .mr-xl-24,
  .mx-xl-24 {
    margin-right: 12.5rem !important;
  }
  .mb-xl-24,
  .my-xl-24 {
    margin-bottom: 12.5rem !important;
  }
  .ml-xl-24,
  .mx-xl-24 {
    margin-left: 12.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2rem !important;
  }
  .p-xl-6 {
    padding: 2.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 2.5rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3rem !important;
  }
  .p-xl-8 {
    padding: 3.5rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 3.5rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 3.5rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 3.5rem !important;
  }
  .p-xl-9 {
    padding: 4rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 4rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 4rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 4rem !important;
  }
  .p-xl-10 {
    padding: 4.5rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 4.5rem !important;
  }
  .p-xl-11 {
    padding: 5rem !important;
  }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 5rem !important;
  }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 5rem !important;
  }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 5rem !important;
  }
  .p-xl-12 {
    padding: 5.5rem !important;
  }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 5.5rem !important;
  }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 5.5rem !important;
  }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 5.5rem !important;
  }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 5.5rem !important;
  }
  .p-xl-13 {
    padding: 6rem !important;
  }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 6rem !important;
  }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 6rem !important;
  }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 6rem !important;
  }
  .p-xl-14 {
    padding: 6.5rem !important;
  }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 6.5rem !important;
  }
  .pr-xl-14,
  .px-xl-14 {
    padding-right: 6.5rem !important;
  }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 6.5rem !important;
  }
  .pl-xl-14,
  .px-xl-14 {
    padding-left: 6.5rem !important;
  }
  .p-xl-15 {
    padding: 7rem !important;
  }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 7rem !important;
  }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 7rem !important;
  }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 7rem !important;
  }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 7rem !important;
  }
  .p-xl-16 {
    padding: 7.5rem !important;
  }
  .pt-xl-16,
  .py-xl-16 {
    padding-top: 7.5rem !important;
  }
  .pr-xl-16,
  .px-xl-16 {
    padding-right: 7.5rem !important;
  }
  .pb-xl-16,
  .py-xl-16 {
    padding-bottom: 7.5rem !important;
  }
  .pl-xl-16,
  .px-xl-16 {
    padding-left: 7.5rem !important;
  }
  .p-xl-17 {
    padding: 8rem !important;
  }
  .pt-xl-17,
  .py-xl-17 {
    padding-top: 8rem !important;
  }
  .pr-xl-17,
  .px-xl-17 {
    padding-right: 8rem !important;
  }
  .pb-xl-17,
  .py-xl-17 {
    padding-bottom: 8rem !important;
  }
  .pl-xl-17,
  .px-xl-17 {
    padding-left: 8rem !important;
  }
  .p-xl-18 {
    padding: 8.5rem !important;
  }
  .pt-xl-18,
  .py-xl-18 {
    padding-top: 8.5rem !important;
  }
  .pr-xl-18,
  .px-xl-18 {
    padding-right: 8.5rem !important;
  }
  .pb-xl-18,
  .py-xl-18 {
    padding-bottom: 8.5rem !important;
  }
  .pl-xl-18,
  .px-xl-18 {
    padding-left: 8.5rem !important;
  }
  .p-xl-19 {
    padding: 9rem !important;
  }
  .pt-xl-19,
  .py-xl-19 {
    padding-top: 9rem !important;
  }
  .pr-xl-19,
  .px-xl-19 {
    padding-right: 9rem !important;
  }
  .pb-xl-19,
  .py-xl-19 {
    padding-bottom: 9rem !important;
  }
  .pl-xl-19,
  .px-xl-19 {
    padding-left: 9rem !important;
  }
  .p-xl-20 {
    padding: 9.5rem !important;
  }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 9.5rem !important;
  }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 9.5rem !important;
  }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 9.5rem !important;
  }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 9.5rem !important;
  }
  .p-xl-21 {
    padding: 10rem !important;
  }
  .pt-xl-21,
  .py-xl-21 {
    padding-top: 10rem !important;
  }
  .pr-xl-21,
  .px-xl-21 {
    padding-right: 10rem !important;
  }
  .pb-xl-21,
  .py-xl-21 {
    padding-bottom: 10rem !important;
  }
  .pl-xl-21,
  .px-xl-21 {
    padding-left: 10rem !important;
  }
  .p-xl-22 {
    padding: 11.5rem !important;
  }
  .pt-xl-22,
  .py-xl-22 {
    padding-top: 11.5rem !important;
  }
  .pr-xl-22,
  .px-xl-22 {
    padding-right: 11.5rem !important;
  }
  .pb-xl-22,
  .py-xl-22 {
    padding-bottom: 11.5rem !important;
  }
  .pl-xl-22,
  .px-xl-22 {
    padding-left: 11.5rem !important;
  }
  .p-xl-23 {
    padding: 12rem !important;
  }
  .pt-xl-23,
  .py-xl-23 {
    padding-top: 12rem !important;
  }
  .pr-xl-23,
  .px-xl-23 {
    padding-right: 12rem !important;
  }
  .pb-xl-23,
  .py-xl-23 {
    padding-bottom: 12rem !important;
  }
  .pl-xl-23,
  .px-xl-23 {
    padding-left: 12rem !important;
  }
  .p-xl-24 {
    padding: 12.5rem !important;
  }
  .pt-xl-24,
  .py-xl-24 {
    padding-top: 12.5rem !important;
  }
  .pr-xl-24,
  .px-xl-24 {
    padding-right: 12.5rem !important;
  }
  .pb-xl-24,
  .py-xl-24 {
    padding-bottom: 12.5rem !important;
  }
  .pl-xl-24,
  .px-xl-24 {
    padding-left: 12.5rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2rem !important;
  }
  .m-xl-n6 {
    margin: -2.5rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -2.5rem !important;
  }
  .m-xl-n7 {
    margin: -3rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -3rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -3rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -3rem !important;
  }
  .m-xl-n8 {
    margin: -3.5rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -3.5rem !important;
  }
  .m-xl-n9 {
    margin: -4rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -4rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -4rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -4rem !important;
  }
  .m-xl-n10 {
    margin: -4.5rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -4.5rem !important;
  }
  .m-xl-n11 {
    margin: -5rem !important;
  }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -5rem !important;
  }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -5rem !important;
  }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -5rem !important;
  }
  .m-xl-n12 {
    margin: -5.5rem !important;
  }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -5.5rem !important;
  }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -5.5rem !important;
  }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -5.5rem !important;
  }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -5.5rem !important;
  }
  .m-xl-n13 {
    margin: -6rem !important;
  }
  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -6rem !important;
  }
  .mr-xl-n13,
  .mx-xl-n13 {
    margin-right: -6rem !important;
  }
  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n13,
  .mx-xl-n13 {
    margin-left: -6rem !important;
  }
  .m-xl-n14 {
    margin: -6.5rem !important;
  }
  .mt-xl-n14,
  .my-xl-n14 {
    margin-top: -6.5rem !important;
  }
  .mr-xl-n14,
  .mx-xl-n14 {
    margin-right: -6.5rem !important;
  }
  .mb-xl-n14,
  .my-xl-n14 {
    margin-bottom: -6.5rem !important;
  }
  .ml-xl-n14,
  .mx-xl-n14 {
    margin-left: -6.5rem !important;
  }
  .m-xl-n15 {
    margin: -7rem !important;
  }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -7rem !important;
  }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -7rem !important;
  }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -7rem !important;
  }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -7rem !important;
  }
  .m-xl-n16 {
    margin: -7.5rem !important;
  }
  .mt-xl-n16,
  .my-xl-n16 {
    margin-top: -7.5rem !important;
  }
  .mr-xl-n16,
  .mx-xl-n16 {
    margin-right: -7.5rem !important;
  }
  .mb-xl-n16,
  .my-xl-n16 {
    margin-bottom: -7.5rem !important;
  }
  .ml-xl-n16,
  .mx-xl-n16 {
    margin-left: -7.5rem !important;
  }
  .m-xl-n17 {
    margin: -8rem !important;
  }
  .mt-xl-n17,
  .my-xl-n17 {
    margin-top: -8rem !important;
  }
  .mr-xl-n17,
  .mx-xl-n17 {
    margin-right: -8rem !important;
  }
  .mb-xl-n17,
  .my-xl-n17 {
    margin-bottom: -8rem !important;
  }
  .ml-xl-n17,
  .mx-xl-n17 {
    margin-left: -8rem !important;
  }
  .m-xl-n18 {
    margin: -8.5rem !important;
  }
  .mt-xl-n18,
  .my-xl-n18 {
    margin-top: -8.5rem !important;
  }
  .mr-xl-n18,
  .mx-xl-n18 {
    margin-right: -8.5rem !important;
  }
  .mb-xl-n18,
  .my-xl-n18 {
    margin-bottom: -8.5rem !important;
  }
  .ml-xl-n18,
  .mx-xl-n18 {
    margin-left: -8.5rem !important;
  }
  .m-xl-n19 {
    margin: -9rem !important;
  }
  .mt-xl-n19,
  .my-xl-n19 {
    margin-top: -9rem !important;
  }
  .mr-xl-n19,
  .mx-xl-n19 {
    margin-right: -9rem !important;
  }
  .mb-xl-n19,
  .my-xl-n19 {
    margin-bottom: -9rem !important;
  }
  .ml-xl-n19,
  .mx-xl-n19 {
    margin-left: -9rem !important;
  }
  .m-xl-n20 {
    margin: -9.5rem !important;
  }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -9.5rem !important;
  }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -9.5rem !important;
  }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -9.5rem !important;
  }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -9.5rem !important;
  }
  .m-xl-n21 {
    margin: -10rem !important;
  }
  .mt-xl-n21,
  .my-xl-n21 {
    margin-top: -10rem !important;
  }
  .mr-xl-n21,
  .mx-xl-n21 {
    margin-right: -10rem !important;
  }
  .mb-xl-n21,
  .my-xl-n21 {
    margin-bottom: -10rem !important;
  }
  .ml-xl-n21,
  .mx-xl-n21 {
    margin-left: -10rem !important;
  }
  .m-xl-n22 {
    margin: -11.5rem !important;
  }
  .mt-xl-n22,
  .my-xl-n22 {
    margin-top: -11.5rem !important;
  }
  .mr-xl-n22,
  .mx-xl-n22 {
    margin-right: -11.5rem !important;
  }
  .mb-xl-n22,
  .my-xl-n22 {
    margin-bottom: -11.5rem !important;
  }
  .ml-xl-n22,
  .mx-xl-n22 {
    margin-left: -11.5rem !important;
  }
  .m-xl-n23 {
    margin: -12rem !important;
  }
  .mt-xl-n23,
  .my-xl-n23 {
    margin-top: -12rem !important;
  }
  .mr-xl-n23,
  .mx-xl-n23 {
    margin-right: -12rem !important;
  }
  .mb-xl-n23,
  .my-xl-n23 {
    margin-bottom: -12rem !important;
  }
  .ml-xl-n23,
  .mx-xl-n23 {
    margin-left: -12rem !important;
  }
  .m-xl-n24 {
    margin: -12.5rem !important;
  }
  .mt-xl-n24,
  .my-xl-n24 {
    margin-top: -12.5rem !important;
  }
  .mr-xl-n24,
  .mx-xl-n24 {
    margin-right: -12.5rem !important;
  }
  .mb-xl-n24,
  .my-xl-n24 {
    margin-bottom: -12.5rem !important;
  }
  .ml-xl-n24,
  .mx-xl-n24 {
    margin-left: -12.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 400 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #286efb !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0448d3 !important;
}

.text-secondary {
  color: #6a747b !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #474d52 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f6f9fc !important;
}

a.text-light:hover, a.text-light:focus {
  color: #bdd3e9 !important;
}

.text-dark {
  color: #2d343e !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #0d0f12 !important;
}

.text-body {
  color: #6a747b !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #b4b9bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #ffffff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dfe2e5 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  width: 10rem;
}

@media (min-width: 992px) {
  .navbar-brand.medium {
    width: 20rem;
  }
  .dropdown-item {
    padding: 0.5rem 2.5rem 0.5rem 1.5rem;
  }
  .navbar-nav li.dropdown:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    -webkit-transform: translateX(-50%) translateY(-3px) scale(1);
            transform: translateX(-50%) translateY(-3px) scale(1);
  }
  .navbar-nav li.dropdown > .dropdown-menu {
    display: block;
    margin: 0;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-3px) scale(0.97);
            transform: translateX(-50%) translateY(-3px) scale(0.97);
    transition: all .2s ease;
    top: auto;
    background-color: #fff;
    border-radius: 3px;
    opacity: 0;
    pointer-events: none;
    border: none;
    visibility: hidden;
  }
  .navbar-nav .dropdown-submenu > .dropdown-menu {
    left: 100%;
    -webkit-transform: translateX(0) translateY(-3px) scale(0.97);
            transform: translateX(0) translateY(-3px) scale(0.97);
    display: block;
    margin: 0 0 0 -0.8rem;
    transition: all .2s ease;
    background-color: #fff;
    border-radius: 3px;
    right: auto;
    top: 0;
    opacity: 0;
    pointer-events: none;
    z-index: 1;
  }
  .navbar-nav .dropdown-submenu:hover > .dropdown-menu {
    -webkit-transform: none;
            transform: none;
    transition: none;
    display: block;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    left: 100%;
    z-index: 9;
  }
}

@media (max-width: 991.98px) {
  .navbar-collapse {
    position: absolute;
    top: 70px;
    background: #ffffff;
    width: 100%;
    padding: 1rem 2rem;
    box-shadow: 0px 0px 30px rgba(83, 88, 93, 0.15);
    left: 0;
  }
  .navbar-toggler {
    border: none;
  }
}

.nav-link {
  padding: .5rem 1rem;
  font-weight: 600;
}

/*buttons*/
.btn {
  font-weight: 600;
}

.btn.btn-md {
  padding: 0.70rem 1.5rem;
}

.btn.btn-xs {
  font-size: 0.6875rem;
  font-weight: 400;
  padding: 0.4375rem 0.9375rem;
}

.btn.btn-lg, .btn-group-lg > .btn {
  padding: 0.70rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn.btn-wide {
  min-width: 9.6875rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.btn.btn-pill {
  border-radius: 6.1875rem;
}

.btn.btn-primary:hover {
  box-shadow: 0 4px 11px rgba(40, 110, 251, 0.35);
}

.btn.btn-secondary:hover {
  box-shadow: 0 4px 11px rgba(106, 116, 123, 0.35);
}

.btn.btn-success:hover {
  box-shadow: 0 4px 11px rgba(40, 167, 69, 0.35);
}

.btn.btn-danger:hover {
  box-shadow: 0 4px 11px rgba(220, 53, 69, 0.35);
}

.btn.btn-warning:hover {
  box-shadow: 0 4px 11px rgba(255, 193, 7, 0.35);
}

.btn.btn-info:hover {
  box-shadow: 0 4px 11px rgba(23, 162, 184, 0.35);
}

.btn.btn-light:hover {
  box-shadow: 0 4px 11px rgba(246, 249, 252, 0.35);
}

.btn.btn-white:hover {
  box-shadow: 0 4px 11px rgba(255, 255, 255, 0.35);
}

.btn.btn-dark:hover {
  box-shadow: 0 4px 11px rgba(45, 52, 62, 0.35);
}

.btn.btn-light-primary {
  background: rgba(40, 110, 251, 0.1);
  color: #286efb;
}

.btn.btn-light-primary:hover {
  color: #ffffff;
  background-color: #286efb;
  box-shadow: 0 4px 11px rgba(40, 110, 251, 0.35);
}

.btn.btn-light-secondary {
  background: rgba(106, 116, 123, 0.1);
  color: #6a747b;
}

.btn.btn-light-secondary:hover {
  color: #ffffff;
  background-color: #6a747b;
  box-shadow: 0 4px 11px rgba(106, 116, 123, 0.35);
}

.btn.btn-light-success {
  background: rgba(40, 167, 69, 0.1);
  color: #28a745;
}

.btn.btn-light-success:hover {
  color: #ffffff;
  background-color: #28a745;
  box-shadow: 0 4px 11px rgba(40, 167, 69, 0.35);
}

.btn.btn-light-danger {
  background: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

.btn.btn-light-danger:hover {
  color: #ffffff;
  background-color: #dc3545;
  box-shadow: 0 4px 11px rgba(220, 53, 69, 0.35);
}

.btn.btn-light-warning {
  background: rgba(255, 193, 7, 0.1);
  color: #ffc107;
}

.btn.btn-light-warning:hover {
  color: #ffffff;
  background-color: #ffc107;
  box-shadow: 0 4px 11px rgba(255, 193, 7, 0.35);
}

.btn.btn-light-info {
  background: rgba(23, 162, 184, 0.1);
  color: #17a2b8;
}

.btn.btn-light-info:hover {
  color: #ffffff;
  background-color: #17a2b8;
  box-shadow: 0 4px 11px rgba(23, 162, 184, 0.35);
}

.btn.btn-light-light {
  background: rgba(246, 249, 252, 0.1);
}

.btn.btn-light-light:hover {
  background-color: #f6f9fc;
  box-shadow: 0 4px 11px rgba(246, 249, 252, 0.35);
}

.btn.btn-light-white {
  background: rgba(255, 255, 255, 0.1);
}

.btn.btn-light-white:hover {
  background-color: #ffffff;
  box-shadow: 0 4px 11px rgba(255, 255, 255, 0.35);
}

.btn.btn-light-dark {
  background: rgba(45, 52, 62, 0.1);
  color: #2d343e;
}

.btn.btn-light-dark:hover {
  color: #ffffff;
  background-color: #2d343e;
  box-shadow: 0 4px 11px rgba(45, 52, 62, 0.35);
}

.custom-file {
  height: 45px;
}

.custom-file-input {
  height: 100%;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.75rem 1.5rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.8;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.4;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  font-size: 13px;
  padding: 5px 20px;
  line-height: 1.6;
}

.badge-lg {
  padding: .7em 1.15em;
}

.badge-primary-soft {
  background-color: rgba(40, 110, 251, 0.1);
  color: #286efb;
}

a.badge-primary-soft:focus,
a.badge-primary-soft:hover {
  background-color: rgba(40, 110, 251, 0.2);
  color: #286efb;
}

.badge-secondary-soft {
  background-color: rgba(106, 116, 123, 0.1);
  color: #6a747b;
}

a.badge-secondary-soft:focus,
a.badge-secondary-soft:hover {
  background-color: rgba(106, 116, 123, 0.2);
  color: #6a747b;
}

.badge-success-soft {
  background-color: rgba(40, 167, 69, 0.1);
  color: #28a745;
}

a.badge-success-soft:focus,
a.badge-success-soft:hover {
  background-color: rgba(40, 167, 69, 0.2);
  color: #28a745;
}

.badge-info-soft {
  background-color: rgba(23, 162, 184, 0.1);
  color: #17a2b8;
}

a.badge-info-soft:focus,
a.badge-info-soft:hover {
  background-color: rgba(23, 162, 184, 0.2);
  color: #17a2b8;
}

.badge-warning-soft {
  background-color: rgba(255, 193, 7, 0.1);
  color: #ffc107;
}

a.badge-warning-soft:focus,
a.badge-warning-soft:hover {
  background-color: rgba(255, 193, 7, 0.2);
  color: #ffc107;
}

.badge-danger-soft {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

a.badge-danger-soft:focus,
a.badge-danger-soft:hover {
  background-color: rgba(220, 53, 69, 0.2);
  color: #dc3545;
}

.badge-light-soft {
  background-color: rgba(246, 249, 252, 0.1);
  color: #f6f9fc;
}

a.badge-light-soft:focus,
a.badge-light-soft:hover {
  background-color: rgba(246, 249, 252, 0.2);
  color: #f6f9fc;
}

.badge-dark-soft {
  background-color: rgba(45, 52, 62, 0.1);
  color: #2d343e;
}

a.badge-dark-soft:focus,
a.badge-dark-soft:hover {
  background-color: rgba(45, 52, 62, 0.2);
  color: #2d343e;
}

.badge-white-soft {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

a.badge-white-soft:focus,
a.badge-white-soft:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.badge-white-soft.active {
  background-color: #ffffff;
  color: #286efb;
}

.badge-white-soft.active:focus,
.badge-white-soft.active:hover {
  background-color: #f6f9fc;
  color: #286efb;
}

/**
 * Cavistar | Multipurpose Responsive Template
 * Theme general styles
 */
/* common styles */
*:focus {
  outline: none !important;
}

::-moz-selection {
  color: #ffffff;
  background: #286efb;
}

::selection {
  color: #ffffff;
  background: #286efb;
}

::-moz-selection {
  color: #ffffff;
  background: #286efb;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-x: hidden;
}

.container, .container-fluid, .container-xl {
  position: relative;
  z-index: 1;
}

a {
  transition: all 0.3s ease-in-out;
}

img {
  max-width: 100%;
  height: auto;
}

video {
  background-size: cover;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

iframe {
  border: 0;
}

.card-img-top, .card-img-bottom {
  min-height: 1px;
}

.bg-primary::-moz-selection {
  color: #286efb;
  background: #ffffff;
}

.bg-primary::selection {
  color: #286efb;
  background: #ffffff;
}

.bg-primary {
  color: #286efb;
  background: #ffffff;
}

a[href^=tel] {
  color: inherit;
  text-decoration: none;
}

.custom-select {
  -webkit-appearance: none;
}

@media print {
  .print-hide {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .lead {
    font-size: 1.1875rem;
  }
}

@media (max-width: 1199.98px) {
  .container {
    width: 94%;
  }
}

@media (max-width: 575.98px) {
  .container {
    width: 100%;
    padding: 0 15px;
  }
}

/* loader */
#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: #ffffff;
  display: table;
  text-align: center;
}

.loader {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  width: 200px;
  height: 200px;
}

.loader-icon {
  width: 80px;
  height: 80px;
  border: 5px solid #286efb;
  border-right-color: #eee;
  border-radius: 50%;
  position: relative;
  -webkit-animation: loader-rotate 1s linear infinite;
          animation: loader-rotate 1s linear infinite;
  margin: 0 auto;
}

@-webkit-keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* font family */
.body-font {
  font-family: "Nunito", sans-serif !important;
}

.title-font {
  font-family: "Nunito", sans-serif !important;
}

.alt-font {
  font-family: "Nunito", sans-serif !important;
}

/* svg icons */
.feather {
  width: 1em;
  height: 1em;
  fill: none;
  stroke: currentcolor;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  color: #286efb;
}

.svg-injector {
  width: auto;
  height: auto;
  fill: none;
  stroke: currentcolor;
  stroke-width: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
  color: #286efb;
}

.feather.white {
  color: #ffffff;
}

/* jarallax border radius */
.border-radius-before > [id*="jarallax-container-"] {
  border-radius: 3px !important;
}

/* animated headline */
.cd-headline.type .cd-words-wrapper.selected {
  background-color: #ffc107;
}

.cd-headline.loading-bar .cd-words-wrapper::after {
  background-color: #286efb;
}

/* transitions */
.transition-hover {
  transition: all 0.14s ease-in-out;
}

.transition-hover:hover, .transition-hover:focus {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

/* border colors */
.border-light-white {
  border-color: rgba(225, 225, 225, 0.15) !important;
}

.border-light-dark {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.border-vlight-dark {
  border-color: rgba(0, 0, 0, 0.08) !important;
}

/* sections */
section {
  position: relative;
  padding: 4rem 0;
}

section.bottom-shape {
  padding-bottom: 4.8rem;
}

section.top-shape {
  padding-top: 4.8rem;
}

@media (min-width: 992px) {
  section {
    padding: 5rem 0;
  }
  section.bottom-shape {
    padding-bottom: 6rem;
  }
  section.top-shape {
    padding-top: 6rem;
  }
}

@media (min-width: 1200px) {
  section {
    padding: 7rem 0;
  }
  section.bottom-shape {
    padding-bottom: 8.5rem;
  }
  section.top-shape {
    padding-top: 8.5rem;
  }
}

/* round shape */
.bg-round-shape {
  position: absolute;
  left: 0;
  bottom: -3px;
  top: auto;
  width: 100%;
  z-index: 0;
}

.bg-round-shape.top {
  top: -3px;
  bottom: auto;
}

.bg-round-shape.reverse {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

@media (min-width: 576px) {
  .bottom-shape .bg-round-shape.reverse {
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .bottom-shape .bg-round-shape.reverse {
    bottom: -1px;
  }
}

@media (max-width: 575.98px) {
  .bg-round-shape.bottom-0 {
    bottom: -3px;
  }
}

/* breadcrumb */
.breadcrumb {
  background: transparent;
  margin: 0;
}

.breadcrumb .breadcrumb-item {
  display: inline-block;
}

.breadcrumb .breadcrumb-item a {
  color: #286efb;
}

.breadcrumb > .breadcrumb-item + .breadcrumb-item:before {
  content: "\f054";
  padding: 0 0.5rem 0 5px;
  font-family: "Font Awesome 5 Free";
  font-size: 13px;
  vertical-align: middle;
  font-weight: 700;
}

/* SVG IE10+ specific styles go here */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .navbar-brand {
    height: 40px;
    box-sizing: unset;
  }
  .navbar-toggler {
    width: 30px;
    height: 38px;
    right: 0;
    position: absolute;
    top: 20px;
  }
  /*height*/
  .ie-height-71 {
    height: 71px;
  }
  .ie-height-83 {
    height: 83px;
  }
  .ie-height-90 {
    height: 90px;
  }
  .ie-height-100 {
    height: 100px;
  }
  .ie-height-110 {
    height: 110px;
  }
  .ie-height-115 {
    height: 115px;
  }
  .ie-height-130 {
    height: 130px;
  }
  .ie-height-175 {
    height: 175px;
  }
  /*width*/
  .ie-width-170 {
    width: 170px;
  }
  .ie-width-80 {
    width: 80%;
  }
}

/* scroll to top */
.scroll-to-top {
  font-size: 20px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  border-radius: 50%;
  background: #000;
  border: 1px solid #2a2a2a;
  width: 35px;
  height: 35px;
  line-height: 35px;
  z-index: 9999;
  outline: 0;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all 0.3s ease;
}

.scroll-to-top i {
  color: #fff;
}

.scroll-to-top:hover {
  color: #232323;
  background: #fff;
}

.scroll-to-top:hover i {
  color: #232323;
}

.scroll-to-top:visited {
  color: #232323;
}

/* icons */
.icon-style-two .icon {
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  padding: 10px;
  line-height: 2.2rem;
  text-align: center;
  background-color: rgba(40, 110, 251, 0.1);
  color: #286efb;
  height: 3.50rem;
  width: 3.50rem;
}

.icon-style-two svg {
  font-size: 1.8rem;
}

.icon-style-two span {
  color: #495057;
  font-size: 1.1rem;
}

/* list style */
.list-style1 i {
  color: #286efb;
  font-size: 12px;
  background: rgba(40, 110, 251, 0.1);
  border-radius: 30px;
  padding: 7px;
  line-height: 13px;
}

.list-style2 i {
  color: #ffffff;
  font-size: 12px;
  background: rgba(40, 167, 69, 0.8);
  border-radius: 30px;
  padding: 7px;
  line-height: 13px;
}

.list-style3 i {
  color: #28a745;
  font-size: 12px;
  background: rgba(40, 167, 69, 0.2);
  border-radius: 30px;
  padding: 7px;
  line-height: 13px;
}

.list-style5 i {
  color: #ffffff;
  font-size: 12px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  padding: 7px;
  line-height: 13px;
}

/* about us */
.card-standard {
  z-index: 9;
  border-radius: 4px;
}

.card-enterprise {
  border-radius: 4px;
}

@media (min-width: 768px) {
  .card-enterprise {
    border-radius: 0 4px 4px 0;
  }
}

/* quick contact */
.quick-contact {
  position: relative;
  z-index: 999;
}

@media (min-width: 992px) {
  .quick-contact .form-control {
    padding: 0.8rem 1rem;
  }
}

/* blockquote */
blockquote.comment {
  border-left: 3px solid #e5e5e5;
}

/**
 * Cavistar | Multipurpose Responsive Template
 * Theme helper styles
 */
.bg-cover {
  background: no-repeat center center/cover;
}

.sm-avatar {
  width: 2.75rem;
  height: 2.75rem;
}

.md-avatar {
  width: 3.75rem;
  height: 3.75rem;
}

.max-width-content {
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
  width: -o-max-content;
  display: inline-block;
  max-width: 100%;
}

/*positions*/
.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

/* z-index */
.z-index-n9 {
  z-index: -9;
  position: relative;
}

.z-index-9 {
  z-index: 9;
  position: relative;
}

.z-index-99 {
  z-index: 99;
  position: relative;
}

/*overflow*/
.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

/* hover styles */
.hover-white:hover {
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.hover-opacity:hover {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.hover-primary:hover {
  color: #286efb;
  transition: all 0.3s ease-in-out;
}

/* background color and opacity */
.bg-transparent {
  background: transparent !important;
}

.bg-white-10 {
  background: rgba(255, 255, 255, 0.1);
}

.bg-primary-10 {
  background: rgba(40, 110, 251, 0.1);
}

.bg-primary-20 {
  background: rgba(40, 110, 251, 0.2);
}

.bg-primary-30 {
  background: rgba(40, 110, 251, 0.3);
}

.bg-primary-40 {
  background: rgba(40, 110, 251, 0.4);
}

.bg-primary-50 {
  background: rgba(40, 110, 251, 0.5);
}

.bg-primary-60 {
  background: rgba(40, 110, 251, 0.6);
}

.bg-primary-70 {
  background: rgba(40, 110, 251, 0.7);
}

.bg-primary-80 {
  background: rgba(40, 110, 251, 0.8);
}

.bg-primary-90 {
  background: rgba(40, 110, 251, 0.9);
}

.bg-danger-10 {
  background: rgba(220, 53, 69, 0.1);
}

.bg-danger-20 {
  background: rgba(220, 53, 69, 0.2);
}

.bg-danger-30 {
  background: rgba(220, 53, 69, 0.3);
}

.bg-danger-40 {
  background: rgba(220, 53, 69, 0.4);
}

.bg-danger-50 {
  background: rgba(220, 53, 69, 0.5);
}

.bg-danger-60 {
  background: rgba(220, 53, 69, 0.6);
}

.bg-danger-70 {
  background: rgba(220, 53, 69, 0.7);
}

.bg-danger-80 {
  background: rgba(220, 53, 69, 0.8);
}

.bg-danger-90 {
  background: rgba(220, 53, 69, 0.9);
}

/* overlay */
.overlay-gradient:before {
  background: rgba(0, 0, 0, 0.76);
  background: linear-gradient(-180deg, transparent, #000 116%);
}

.overlay:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay-primary:before {
  background-color: #286efb;
}

.overlay-black:before {
  background-color: #000000;
}

.overlay-white:before {
  background-color: #ffffff;
}

.overlay-warning:before {
  background-color: #ffc107;
}

.overlay-10:before {
  opacity: 0.1;
}

.overlay-20:before {
  opacity: 0.2;
}

.overlay-30:before {
  opacity: 0.3;
}

.overlay-40:before {
  opacity: 0.4;
}

.overlay-50:before {
  opacity: 0.5;
}

.overlay-60:before {
  opacity: 0.6;
}

.overlay-70:before {
  opacity: 0.7;
}

.overlay-75:before {
  opacity: 0.75;
}

.overlay-80:before {
  opacity: 0.8;
}

.overlay-85:before {
  opacity: 0.85;
}

.overlay-90:before {
  opacity: 0.9;
}

.overlay-95:before {
  opacity: 0.95;
}

/* opacity */
.opacity1 {
  opacity: 0.1;
}

.opacity2 {
  opacity: 0.2;
}

.opacity3 {
  opacity: 0.3;
}

.opacity4 {
  opacity: 0.4;
}

.opacity5 {
  opacity: 0.5;
}

.opacity6 {
  opacity: 0.6;
}

.opacity7 {
  opacity: 0.7;
}

.opacity75 {
  opacity: 0.75;
}

.opacity8 {
  opacity: 0.8;
}

.opacity85 {
  opacity: 0.85;
}

.opacity9 {
  opacity: 0.9;
}

.opacity95 {
  opacity: 0.95;
}

/*SVG fill colors*/
.fill-primary {
  fill: #286efb;
}

.fill-white {
  fill: #ffffff;
}

.fill-light {
  fill: #f6f9fc;
}

.fill-none {
  fill: none;
}

.fill-dark {
  fill: #2d343e;
}

/* text colors */
.text-black-60 {
  color: rgba(0, 0, 0, 0.6);
}

.text-black-70 {
  color: rgba(0, 0, 0, 0.7);
}

.text-black-75 {
  color: rgba(0, 0, 0, 0.75);
}

.text-black-80 {
  color: rgba(0, 0, 0, 0.8);
}

.text-black-85 {
  color: rgba(0, 0, 0, 0.85);
}

.text-black-90 {
  color: rgba(0, 0, 0, 0.9);
}

.text-black-95 {
  color: rgba(0, 0, 0, 0.95);
}

.text-white-60 {
  color: rgba(255, 255, 255, 0.6);
}

.text-white-70 {
  color: rgba(255, 255, 255, 0.7);
}

.text-white-75 {
  color: rgba(255, 255, 255, 0.75);
}

.text-white-80 {
  color: rgba(255, 255, 255, 0.8);
}

.text-white-85 {
  color: rgba(255, 255, 255, 0.85);
}

.text-white-90 {
  color: rgba(255, 255, 255, 0.9);
}

.text-white-95 {
  color: rgba(255, 255, 255, 0.95);
}

/*line-height*/
.line-height-base {
  line-height: 1.6 !important;
}

.line-height-lg {
  line-height: 1.8 !important;
}

.line-height-sm {
  line-height: 1.4 !important;
}

/*shadow*/
.shadow {
  box-shadow: 0px 0px 30px rgba(83, 88, 93, 0.15) !important;
}

.shadow-light {
  box-shadow: 0px 0px 10px rgba(83, 88, 93, 0.1);
}

.no-shadow {
  box-shadow: none !important;
}

.shadow-hover {
  transition: all 0.3s ease-in-out;
}

.shadow-hover:hover {
  box-shadow: 0px 0px 50px rgba(83, 88, 93, 0.1);
}

.shadow-hover-1:hover {
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.06);
}

/*border and border radius*/
.border-light {
  border-color: rgba(0, 0, 0, 0.06) !important;
}

.border-radius-3 {
  border-radius: 3px !important;
}

.border-radius-before {
  border-radius: 3px !important;
}

.border-radius-before:before {
  border-radius: 3px !important;
}

.border-radius-left-0 {
  border-radius: 0px 3px 3px 0px !important;
}

.border-radius-right-0 {
  border-radius: 3px 0px 0px 3px !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-right-0 {
  border-right: 0;
}

.border-left-0 {
  border-left: 0;
}

/*border style extend*/
@media (min-width: 576px) {
  .border-sm-right {
    border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
  .border-sm-left {
    border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
}

@media (min-width: 768px) {
  .border-md-right {
    border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
  .border-md-left {
    border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
}

@media (min-width: 992px) {
  .border-lg-right {
    border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
  .border-lg-left {
    border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
}

@media (min-width: 1200px) {
  .border-xl-right {
    border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
  .border-xl-left {
    border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
}

/* height */
.h-300 {
  height: 300px;
}

.h-350 {
  height: 350px;
}

.h-400 {
  height: 400px;
  height: 400px;
}

.h-100vh {
  height: 100vh;
}

.min-height-50vh {
  min-height: 50vh;
}

@media (min-width: 992px) {
  .h-lg-400 {
    height: 400px;
  }
  .h-lg-500 {
    height: 500px;
  }
  .min-height-lg-100vh {
    min-height: 100vh;
  }
  .min-height-lg-90vh {
    min-height: 90vh;
  }
  .min-height-lg-80vh {
    min-height: 80vh;
  }
  .min-height-lg-70vh {
    min-height: 70vh;
  }
  .min-height-lg-60vh {
    min-height: 60vh;
  }
  .min-height-lg-50vh {
    min-height: 50vh;
  }
  .min-height-lg-40vh {
    min-height: 40vh;
  }
  .min-height-lg-35vh {
    min-height: 35vh;
  }
  .min-height-lg-30vh {
    min-height: 30vh;
  }
}

@media (min-width: 1200px) {
  .h-xl-400 {
    height: 400px;
  }
  .h-xl-500 {
    height: 500px;
  }
}

/* width */
.vw-50 {
  width: 50vw;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-15 {
    width: 15% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-35 {
    width: 35% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-45 {
    width: 45% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-65 {
    width: 65% !important;
  }
  .w-sm-70 {
    width: 70% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-85 {
    width: 85% !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-15 {
    width: 15% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-35 {
    width: 35% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-45 {
    width: 45% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-65 {
    width: 65% !important;
  }
  .w-md-70 {
    width: 70% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-85 {
    width: 85% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
}

@media (min-width: 992px) {
  .vw-lg-50 {
    width: 50vw;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-70 {
    width: 70% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-15 {
    width: 15% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-35 {
    width: 35% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-65 {
    width: 65% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-85 {
    width: 85% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-15 {
    width: 15% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-35 {
    width: 35% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-65 {
    width: 65% !important;
  }
  .w-xl-70 {
    width: 70% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-85 {
    width: 85% !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
}

/*display font size extend*/
.display-5 {
  font-size: 3rem;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .display-5 {
    font-size: calc(1.425rem + 2.1vw) ;
  }
}

.display-6 {
  font-size: 2rem;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .display-6 {
    font-size: calc(1.325rem + 0.9vw) ;
  }
}

.display-7 {
  font-size: 1.5rem;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .display-7 {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

.display-8 {
  font-size: 1.2rem;
  font-weight: 500;
}

.display-9 {
  font-size: 1.1rem;
  font-weight: 500;
}

.display-10 {
  font-size: 1rem;
  font-weight: 500;
}

.display-11 {
  font-size: 0.875rem;
  font-weight: 500;
}

.display-12 {
  font-size: 0.8rem;
  font-weight: 500;
}

header {
  position: relative;
  z-index: 99;
}

.navbar-nav .current .nav-link, .navbar-nav .active .dropdown-item, .navbar-nav .dropdown-submenu.current > a {
  color: #286efb !important;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffff;
}

@media (min-width: 992px) {
  .navbar-dark .navbar-nav > .current > .nav-link {
    color: rgba(255, 255, 255, 0.75) !important;
  }
}

@media (max-width: 991.98px) {
  .navbar-dark .navbar-nav .nav-link {
    color: #6a747b;
  }
  .navbar-dark .navbar-nav .nav-link:hover {
    color: #6a747b;
    color: #286efb;
  }
  .navbar-dark .navbar-nav .nav-link:focus {
    color: #286efb;
  }
}

/* footer list style */
.footer-list-style {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-list-style li {
  padding-bottom: 0.5rem;
}

.footer-list-style li a {
  color: #2d343e;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.2;
}

.footer-list-style li a:hover {
  color: #286efb;
}

.footer-list-style li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

/* footer list style */
.footer-list-style-two {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-list-style-two li {
  padding-bottom: 0.875rem;
}

.footer-list-style-two li a {
  color: #6a747b;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
}

.footer-list-style-two li a:hover {
  color: #286efb;
}

.footer-list-style-two li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

/* footer title style2 */
.footer-title-style2 {
  position: relative;
}

.footer-title-style2:after {
  position: absolute;
  content: '';
  background: #286efb;
  width: 60px;
  height: 2px;
  bottom: 2px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

/* footer app buttons */
.app-btn {
  border: 1px solid rgba(255, 255, 255, 0.9);
}

.app-btn .media i {
  font-size: 20px;
}

.app-btn .media-body span {
  font-size: 12px;
}

.accordion > .card {
  overflow: hidden;
  border: none;
}

.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card:last-of-type .card-header {
  margin-bottom: -1px;
}

.accordion > .card:last-of-type .collapse.show {
  border-bottom: none;
}

.accordion > .card .collapse.show {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion > .card .card-header {
  background: none;
  padding: 0;
  margin: 0;
}

.accordion > .card .btn-link {
  padding: 1.5rem 2rem 1.5rem 0;
  width: 100%;
  text-align: left;
  position: relative;
}

.accordion > .card .btn-link.collapsed:after {
  background: none;
  content: "+";
  right: 15px;
  left: inherit;
  font-size: 20px;
  height: auto;
  -webkit-transform: none;
          transform: none;
  width: auto;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 34px;
}

.accordion > .card .btn-link:after {
  background: none;
  content: "-";
  right: 17px;
  left: inherit;
  font-size: 20px;
  height: auto;
  -webkit-transform: none;
          transform: none;
  width: auto;
  position: absolute;
  color: #286efb;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 34px;
}

/* classic banner */
.classic-banner {
  background: url(../img/content/brush-img.png) no-repeat center center;
  background-size: auto;
}

/* main slider */
.main-slider {
  overflow: hidden;
  position: relative;
}

.main-slider .container {
  position: unset;
}

.main-slider .header-shape-bg {
  position: absolute;
  top: 0;
  z-index: 2;
  left: -8px;
  height: 100%;
  max-width: 105px;
  width: 100%;
}

.main-slider .owl-stage-outer, .main-slider .owl-stage, .main-slider .owl-item {
  height: 100%;
}

.slider-section .owl-theme .owl-nav {
  margin: 0;
  position: absolute;
  right: 35px;
  bottom: 20px;
}

.slider-section .owl-theme .owl-nav [class*=owl-] {
  background: #286efb;
  color: #ffffff;
  width: 3rem;
  height: 3rem;
}

.slider-section .owl-theme .owl-nav [class*=owl-]:hover {
  background: #ffffff;
  color: #286efb;
}

@media (min-width: 992px) {
  .main-slider h1 {
    font-size: 2rem;
  }
  .main-slider .min-height {
    min-height: 673px;
  }
}

@media (min-width: 1200px) {
  .main-slider h1 {
    font-size: 3rem;
  }
  .main-slider .header-shape-bg {
    left: -2px;
  }
}

/* classic slider */
.classic-slider.owl-theme .owl-nav.disabled + .owl-dots {
  counter-reset: slides-num;
  position: absolute;
  bottom: 40px;
  right: 50px;
  background: #ffc107;
  padding: 10px;
  color: #ffffff;
  margin: 0;
  width: 120px;
}

.classic-slider.owl-theme .owl-dots .owl-dot {
  counter-increment: slides-num;
  position: relative;
  margin: 0;
}

.classic-slider.owl-theme .owl-dots .owl-dot span {
  display: none;
}

.classic-slider.owl-theme .owl-dots:after {
  content: counter(slides-num);
  font-size: 18px;
  position: relative;
  top: 0;
  left: 8px;
}

.classic-slider.owl-theme .owl-dots .owl-dot.active:before {
  content: counter(slides-num) " /";
  font-size: 26px;
  position: relative;
  top: 0;
  left: 0;
}

/* pricing style */
.pricing-table tr th {
  padding: 1.5rem;
  border-right: 1px solid #dee2e6;
  min-width: 10rem;
}

.pricing-table tr td {
  padding: 1.5rem;
  vertical-align: middle;
  border-right: 1px solid #dee2e6;
}

.pricing-table tr td:last-child {
  border-right: none;
}

@media (min-width: 992px) {
  .pricing-table tr th {
    min-width: 5rem;
  }
}

/* pricing style one */
.pricing-style-one .price span {
  position: absolute;
  top: -14px;
  left: 0;
  right: 0;
  z-index: 0;
  color: #dfe2e5;
}

.pricing-style-one .price h3 {
  z-index: 9;
}

.testimonial-img {
  width: 170px;
}

.quote {
  width: 45px;
  height: 45px;
  position: absolute;
  line-height: 40px;
  border: 2px solid #ffffff;
  text-align: center;
  right: 0;
  bottom: 0;
}

.testimonial p {
  margin-bottom: 2rem;
  line-height: 1.8;
}

.testimonial.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 2rem;
}

@media (min-width: 992px) {
  .testimonial p {
    letter-spacing: 0.5px;
    margin-bottom: 3rem;
    line-height: 1.9;
  }
  .testimonial.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 3rem;
  }
}

/* testimonial style two */
.testimonial-style-two {
  position: relative;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .testimonial-style-two {
    margin-bottom: -145px;
  }
}

.owl-carousel .owl-item img {
  width: auto;
  display: inline-block;
}

.owl-theme .owl-dots .owl-dot {
  margin: 0;
}

.owl-theme .owl-dots .owl-dot span {
  background: rgba(40, 110, 251, 0.2);
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #286efb;
}

/* style 01 */
.social-icon li {
  display: inline-block;
  margin-right: 1rem;
}

.social-icon li:last-child {
  margin-right: 0;
}

.social-icon li a {
  font-size: 1.16rem;
  color: #286efb;
}

/* style 02 */
.social-icon2 li {
  display: inline-block;
  margin-right: 25px;
}

.social-icon2 li:last-child {
  margin-right: 0;
}

.social-icon2 li a {
  font-size: 20px;
  color: #2d343e;
}

.social-icon2.light li a {
  color: #c2c7cc;
}

/* style 03 */
.social-icon3 li {
  display: inline-block;
  margin-right: 5px;
}

.social-icon3 li:last-child {
  margin-right: 0;
}

.social-icon3 li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #286efb;
  text-align: center;
  border-radius: 50%;
  font-size: 15px;
  display: inline-block;
}

.social-icon3 li a:hover {
  background-color: #286efb;
  color: #ffffff;
}

/* style 04 */
.social-icon4 li {
  display: inline-block;
  margin-right: 20px;
}

.social-icon4 li:last-child {
  margin-right: 0;
}

.social-icon4 li a {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.9);
}

.social-icon4 li a:hover {
  color: white;
}

/* tab-style-one */
.tab-style-one .resp-tabs-list {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.tab-style-one .resp-tabs-list li {
  position: relative;
  letter-spacing: 1px;
  display: table-cell;
  padding: 0 15px 15px 15px;
  margin: 0 4px 0 0;
  font-size: 17px;
  list-style: none;
  cursor: pointer;
  min-width: auto;
  float: none;
  color: #000;
  background: none !important;
}

.tab-style-one .resp-tabs-list li.resp-tab-active {
  border: 1px solid #286efb;
  border-bottom: none;
  border-color: #286efb !important;
  margin-bottom: -1px;
  border-top: 4px solid #286efb !important;
  border-bottom: 0px #fff solid;
  border-bottom: none;
  background-color: #fff;
  color: #286efb;
  -ms-border-top-left-radius: 5px;
  -webkit-border-top-left-radius: 5px;
  -moz-border-top-left-radius: 5px;
  -o-border-top-left-radius: 5px;
  -ms-border-top-right-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-top-right-radius: 5px;
  -o-border-top-right-radius: 5px;
  -ms-border-radius-top-left: 5px;
  -webkit-border-radius-top-left: 5px;
  -moz-border-radius-top-left: 5px;
  -o-border-radius-top-left: 5px;
  -ms-border-radius-topright: 5px;
  -webkit-border-radius-topright: 5px;
  -moz-border-radius-topright: 5px;
  -o-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.tab-style-one .resp-tabs-list li.resp-tab-active:after {
  content: "";
  background: #286efb;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
  margin: 0 auto;
  right: 0;
}

.tab-style-one .resp-tab-active {
  padding: 0 15px 15px 15px !important;
}

.tab-style-one .box-shadow-primary {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .tab-style-one ul.resp-tabs-list {
    text-align: center;
    display: table;
    width: 100%;
  }
  .tab-style-one ul.resp-tabs-list li:last-child {
    margin-right: 0;
  }
  .tab-style-one ul.resp-tabs-list li.resp-tab-active {
    color: #286efb;
  }
  .tab-style-one .resp-tab-content {
    border: none;
    padding: 0;
  }
}

@media (max-width: 991.98px) {
  .tab-style-one h2.resp-accordion {
    border-color: #e4e4e4;
    padding: 18px 21px;
    position: relative;
    font-size: 1rem;
  }
  .tab-style-one h2.resp-tab-active {
    border-bottom: 0px solid #e4e4e4 !important;
    border-top: none !important;
    background: #286efb !important;
    color: #fff;
    padding: 18px 21px !important;
  }
  .tab-style-one h2.resp-tab-active.resp-accordion:after {
    color: #ffffff;
  }
  .tab-style-one .resp-tab-content {
    border-color: #e4e4e4;
  }
  .tab-style-one h2.resp-accordion.resp-tab-active {
    border-color: #e4e4e4 !important;
  }
  .tab-style-one h2.resp-accordion:first-child {
    border-top: 1px solid #e4e4e4;
  }
  .tab-style-one ul.resp-tabs-list {
    display: none;
  }
  .tab-style-one h2.resp-accordion {
    display: block;
  }
  .tab-style-one .resp-tab-content {
    border-top: none !important;
  }
  .tab-style-one h2.resp-tab-active {
    background: #ffffff;
  }
  .tab-style-one h2.resp-accordion:after {
    background: none;
    content: "+";
    right: 17px;
    left: inherit;
    font-size: 20px;
    height: auto;
    -webkit-transform: none;
    transform: none;
    width: auto;
    position: absolute;
    color: #286efb;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: 34px;
  }
  .resp-arrow {
    display: none;
  }
  .tab-style-one h2.resp-accordion.resp-tab-active:after {
    background: none;
    content: "-";
    right: 18px;
    left: inherit;
    font-size: 20px;
    height: auto;
    -webkit-transform: none;
    transform: none;
    width: auto;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: 34px;
  }
  .tab-style-one .resp-accordion-closed {
    display: none !important;
  }
  .tab-style-one .resp-tab-content {
    background-color: #fff;
  }
}

.navbar-brand {
  width: 8rem;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: 0px;
  margin-left: 0px;
}

.dropdown-submenu .dropdown-toggle {
  position: relative;
}

.dropdown-submenu .dropdown-toggle:after {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-size: 80%;
  font-weight: 900;
  position: absolute;
  right: 23px;
  top: 12px;
}

.dropdown-toggle:after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 80%;
  font-weight: 900;
  content: "\f107";
  margin-left: 0.5rem;
  border: none;
  vertical-align: middle;
}

.mega-dropdown-menu {
  min-width: 42rem;
}

.mega-dropdown-menu .dropdown-item {
  padding: 0.5rem 0;
}

.navbar-nav .dropdown-img {
  background: no-repeat center center/cover;
  padding: 2.375rem 2.5rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.navbar-nav .dropdown-img:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(40, 110, 251, 0.85);
  border-radius: inherit;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .mega-dropdown-menu {
    min-width: auto;
  }
  .dropdown-toggle:after {
    float: right;
    margin-top: 3px;
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  .navbar-nav .dropdown-toggle[aria-expanded=true]:after {
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    margin-top: 1px;
  }
  .navbar-nav .dropdown-menu {
    box-shadow: none !important;
    padding: 0 0 0.3rem 0;
  }
  .navbar-nav .dropdown-menu .dropdown-menu {
    padding: 0.2rem 0.5rem 0.3rem 0.5rem;
  }
  .dropdown-menu .dropdown-item {
    padding: 0.3rem 0.5rem;
    font-size: 0.95rem;
    white-space: normal;
  }
  .dropdown-menu .dropdown-menu .dropdown-item {
    font-size: 0.9rem;
  }
  .dropdown-submenu .dropdown-item.active:after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .dropdown-submenu .dropdown-toggle:after {
    top: 4px;
    right: 10px;
  }
}

@media (min-width: 992px) {
  .navbar-nav li.dropdown > .mega-dropdown-menu {
    left: 100%;
  }
}

@media (min-width: 1200px) {
  .navbar-nav li.dropdown > .mega-dropdown-menu {
    left: 50%;
  }
}

.story-video .btn-light-primary:hover .feather {
  color: #ffffff;
}

.video-btn {
  -webkit-animation: 2.4s cubic-bezier(0.8, 0, 0, 1) 0s normal none infinite running pulse;
  animation: 2.4s cubic-bezier(0.8, 0, 0, 1) 0s normal none infinite running pulse;
  border: 2px solid #000000;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  display: inline-block;
  font-size: 28px;
  height: 70px;
  line-height: 62px;
  width: 70px;
  margin: 0 20px;
  padding: 0 0 0 5px;
  position: relative;
  text-align: center;
  border-color: #ffffff;
  background: transparent;
  color: #ffffff;
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 20px rgba(194, 244, 246, 0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 20px rgba(194, 244, 246, 0);
  }
}

/* small button */
.video-btn.small {
  -webkit-animation: 2.4s cubic-bezier(0.8, 0, 0, 1) 0s normal none infinite running pulsesmall;
  animation: 2.4s cubic-bezier(0.8, 0, 0, 1) 0s normal none infinite running pulsesmall;
  margin: 0;
  font-size: 21px;
  height: 50px;
  line-height: 42px;
  width: 50px;
}

@-webkit-keyframes pulsesmall {
  to {
    box-shadow: 0 0 0 10px rgba(194, 244, 246, 0);
  }
}

@keyframes pulsesmall {
  to {
    box-shadow: 0 0 0 10px rgba(194, 244, 246, 0);
  }
}

/* common class*/
.form-control {
  height: 43px;
}

.quick-contact .form-control {
  height: 53px;
}

/* quform */
.quform-input {
  position: relative;
}

.quform-element > label {
  font-weight: normal;
  padding-bottom: 5px;
  margin-bottom: 0;
  color: #6a747b;
}

.quform-element > label .quform-required {
  color: #cc0101;
  font-size: 10px;
}

.quform-inner input {
  width: 100%;
}

.quform-elements .quform-element textarea {
  margin-bottom: 0;
  padding: 8px 15px;
  vertical-align: top;
}

.quform-elements .quform-element select {
  margin-bottom: 0;
  padding: 8px 15px;
}

.quform-errors {
  padding: 0;
  margin: 0;
  line-height: normal;
}

.quform-errors > .quform-error {
  padding: 0;
  background: none;
  border: none;
  float: none;
  color: #f5543f;
  font-size: 11px;
  line-height: normal;
}

.quform-outer-no-js .quform-error {
  padding: 0;
  background: none;
  border: none;
  float: none;
  color: #f5543f;
  font-size: 11px;
  line-height: normal;
}

.quform-has-error input, .quform-has-error textarea, .quform-has-error select, .quform-has-error input[type="file"] {
  border-color: #f5543f;
}

.quform-has-error .custom-file-label {
  border-color: #f5543f;
}

.quform-success-message, .quform-outer-no-js .quform-success-message {
  padding: .75rem 1.25rem .75rem 3rem;
}

.quform-input .quform-errors-wrap {
  position: absolute;
  right: 8px;
  top: 0;
  line-height: normal;
}

.quform-submit-inner {
  float: none;
}

.quform-loading-wrap {
  float: none;
}

.quform-loading-wrap .quform-loading {
  display: inline-block;
}

.light-validation .quform-errors > .quform-error {
  color: #fff;
}

/* newsletter */
.newsletter-form .quform-elements {
  position: relative;
}

.newsletter-form .quform-submit-inner {
  position: absolute;
  right: 18px;
  top: -72px;
  width: auto;
}

.newsletter-form .quform-loading-wrap {
  margin-top: 0;
  margin-bottom: 15px;
}

.newsletter-form input {
  border: 2px solid rgba(0, 0, 0, 0.1);
  height: 60px;
  padding: 0.5rem 4rem 0.5rem 1.5rem;
}

.newsletter-form .quform-has-error input, .newsletter-form .quform-has-error textarea, .newsletter-form .quform-has-error select {
  border-color: #f5543f;
}

.newsletter-form .quform-input .quform-errors-wrap {
  right: 15px;
}

.newsletter-form i {
  font-size: 1.5rem;
  line-height: 2rem;
}

.sidenav .nav-link {
  padding: 0.2rem 0;
  color: #6a747b;
  font-weight: 400;
}

.sidenav .nav-item {
  display: inline-block;
  width: 100%;
}

.doc-nav .breadcrumb {
  padding-left: 0;
}

.doc-nav .breadcrumb > .breadcrumb-item + .breadcrumb-item:before {
  content: "\f054";
  padding: 0 0.2rem 0 2px;
  font-family: "Font Awesome 5 Free";
  font-size: 10px;
  vertical-align: middle;
  font-weight: 700;
  color: #ffffff;
}

@media (min-width: 992px) {
  .sidenav {
    position: fixed;
    top: 75px;
    height: calc(100vh - 75px);
    overflow: auto;
  }
  .sidenav-right {
    right: 0;
  }
  .sidenav-left {
    left: 0;
  }
}

/* copy to clipboard */
.html-code {
  background-color: #fbfbfb;
  position: relative;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #ededed;
}

.html-code:before {
  color: rgba(40, 110, 251, 0.15);
  content: "•••";
  font-size: 30px;
  left: 24px;
  letter-spacing: 4px;
  line-height: 12px;
  position: absolute;
  top: 24px;
}

.html-code .copy-element {
  top: 20px;
  right: 30px;
  position: absolute;
  transition: opacity 0.3s ease-in-out;
}

.html-code .copy-clipboard {
  border-radius: 0.25rem;
  background: rgba(40, 110, 251, 0.15);
  color: #6a747b !important;
  display: inline-block;
  padding: 4px 10px;
  font-size: 14px;
  text-transform: capitalize;
  cursor: pointer !important;
  font-weight: 600;
}

.html-code .copy-clipboard:hover {
  background: #286efb;
  color: #ffffff !important;
}

pre[class*=language-] {
  max-height: 45vh;
  height: 100%;
  margin: 35px 0 0 0;
  padding-top: 0;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .doc-nav .navbar-dark {
    width: 30px;
    height: 38px;
    position: relative;
    top: -19px;
  }
}

/*User SCSS*/

/*# sourceMappingURL=theme.css.map */
